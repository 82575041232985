import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListCategories from './ListCategories';
import AddCategories from './AddCategories';
import EditCategories from './EditCategories';

export default function CategoriesRoutes() {
  return (
    <Routes>
      <Route path='/category' element={<ListCategories/>} />
      <Route path='/category-add' element={<AddCategories/>} />
      <Route path='/edit-category/:_id' element={<EditCategories/>} />
   
    </Routes>
  )
}
