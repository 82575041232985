import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListBanner from './ListBanner';
import AddBanner from './AddBanner';
import EditBanner from './EditBanner';

export default function BannerRoutes() {
  return (
    <Routes>
      <Route path='/banner' element={<ListBanner />} />
      <Route path='/banner-add' element={<AddBanner />} />
      <Route path='/edit-banner/:_id' element={<EditBanner/>}/>
    </Routes>
  );
}
