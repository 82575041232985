import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoginRoutes from './Login/LoginRoutes'
import BannerRoutes from './Banner/BannerRoutes';
import DashboardRoutes from './Dashboard/DashboardRoutes';
import CategoriesRoutes from './Categories/CategoriesRoutes';
import SubCategoriesRoutes from './SubCategories/SubCategoriesRoutes';
import CustomerRoutes from './Customer/CustomerRoutes';
import ProductRoutes from './Products/ProductRoutes';
import SubadminRoutes from './Subadmin/subAdminRoutes';
import PromocodeRoutes from './Promocode/PromocodeRoutes';
import AllRoutes from './All/AllRoutes';
import FaqsRoutes from './Faqs/FaqsRoutes';
import AttributeRoutes from './Attributes/AttributesRoutes';
import ReturnTypeRoutes from './ReturnTypes/ReturnTypeRoutes';


function App() {
  return (
    <BrowserRouter>
      <LoginRoutes />
      <DashboardRoutes />
      <BannerRoutes />
      <CategoriesRoutes />
      <SubCategoriesRoutes />
      <CustomerRoutes />
      <ProductRoutes />
      <SubadminRoutes />
      <AllRoutes />
      <PromocodeRoutes />
      <FaqsRoutes />
      <AttributeRoutes />
      <ReturnTypeRoutes/>
    </BrowserRouter>
  );
}

export default App;
