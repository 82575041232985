import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

export default function AddSubCategories() {

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageError, setImageError] = useState("");

  const [subCategory, setSubCategory] = useState({
    name: "",
    parentCategory: "",
    url: "",
    description: "",
    image: null
  });

  function getData(e) {
    const { name, value } = e.target;
    setSubCategory((oldData) => ({
      ...oldData,
      [name]: value
    }));
  }

  function getFile(e) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setImageError("File must be an image");
        return;
      }
      if (file.size > 2 * 1024 * 1024) { // Set limit to 2MB
        setImageError("Image size should be less than 2MB");
        return;
      }
      setImageError("");
      setSubCategory((oldData) => ({
        ...oldData,
        image: file
      }));
    }
  }

  function validateFields() {
    let tempErrors = {};
    if (!subCategory.name) tempErrors.name = "Name is required";
    if (!subCategory.parentCategory) tempErrors.parentCategory = "Parent Category is required";
    if (!subCategory.url) tempErrors.url = "URL is required";
    if (!subCategory.description) tempErrors.description = "Description is required";
    if (!subCategory.image) tempErrors.image = "Image is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }

  async function postData(e) {
    e.preventDefault();
    if (!validateFields()) return;

    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button

    // Implement the logic to add the subcategory
    // const response = await addSubCategory(subCategory); // Assuming you have an addSubCategory function
    // if (response.result.status === 200) navigate("/subcategory");
    // else alert(response.message);

    // setIsDisabled(false); // Remove this line once you implement the add logic
  }

  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="page-content fade-in-up">
            <div className="ibox">
              <div className="ibox-head">
                <div className="ibox-title">
                  <h3>SubCategory - Add</h3>
                </div>
                <Link to="/subcategory" className="btn btn-secondary pull-right">Back</Link>
              </div>
              <div className="ibox-body">
                <form method="post" onSubmit={postData}>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="name" className="control-label">Name<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="name" onChange={getData} maxLength="100" />
                        {errors.name && <small className="text-danger">{errors.name}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="parentCategory" className="control-label">Parent Category<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="parentCategory" onChange={getData} maxLength="100" />
                        {errors.parentCategory && <small className="text-danger">{errors.parentCategory}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="url" className="control-label">URL<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="url" onChange={getData} maxLength="100" />
                        {errors.url && <small className="text-danger">{errors.url}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="image" className="control-label">Image<span className="text-danger">*</span></label>
                        <input type="file" className="form-control" name="image" onChange={getFile} />
                        {imageError && <small className="text-danger">{imageError}</small>}
                        {errors.image && <small className="text-danger">{errors.image}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="description" className="control-label">Description<span className="text-danger">*</span></label>
                        <textarea id="description" className="form-control" name="description" onChange={getData} maxLength="100"></textarea>
                        {errors.description && <small className="text-danger">{errors.description}</small>}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-1"></div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-1">
                      <Link to="/subcategory" className="btn btn-secondary pull-right">Back</Link>
                    </div>
                    <div className="col-md-8 text-center">
                      <div className="form-group">
                        <button className="btn btn-primary btn-rounded btn-block" type="submit" disabled={isButtonDisabled}>
                        {isLoading ? 'SubCategory Adding...' : 'Add SubCategory'}   
                          </button>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
