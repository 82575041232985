import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const SubadminContext = createContext()
//get all products list
async function getSubadmins(page, itemsPerPage, searchQuery) {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var response = await axios.get(`${ADMIN_BASE_URL}/subadmins?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config)
    console.log("response : ", response)
    if (response.status == 200) {
        return { status: true, data: response.data.data };
    } else {
        return { status: false, message: "Internal Server Error" };
    }
}





async function addSubadmin(item) {
    // console.log("Form item+++++++++++++", item)

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            }

        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/subadmins`, item, config);
            // console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}





async function getSubAdminById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/subadmins/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function SubAdminUpdate(item, _id) {

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/subadmins/${_id}`, item, config);
            // console.log('Success:', result);
            if (result) {
                return { result };
            } else {
                return { result: "Fail", message: "Internal Server Error" };
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function deleteSubAdmin(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/subadmins/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Banner is Deleted" }
}



async function SubAdminStatus(_id, status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        status: status
    }
    var result = await axios.put(`${ADMIN_BASE_URL}/subadmins/status/${_id}`, obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Banner is Deleted" }
}







async function getpermissions() {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var response = await axios.get(`${ADMIN_BASE_URL}/subadmins/permissions`, config)
    // console.log("response : ", response)
    if (response.status == 200) {
        return { status: true, data: response.data.data };
    } else {
        return { status: false, message: "Internal Server Error" };
    }
}




async function SubAdminUpdatePermission(permissions, _id) {
    console.log('permissions data', permissions)
    console.log('permissions _id', _id)


    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let obj = {
                permissions : permissions
            }
            let result = await axios.put(`${ADMIN_BASE_URL}/subadmins/permission/${_id}`, obj, config);
            console.log('Success:', result);
            if (result) {
                return { result };
            } else {
                return { result: "Fail", message: "Internal Server Error" };
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




export default function SubadminContextProvider(props) {
    return <SubadminContext.Provider value={
        {
            getSubadmins: getSubadmins,
            addSubadmin: addSubadmin,
            getSubAdminById: getSubAdminById,
            SubAdminUpdate: SubAdminUpdate,
            deleteSubAdmin: deleteSubAdmin,
            SubAdminStatus: SubAdminStatus,
            getpermissions: getpermissions,
            SubAdminUpdatePermission: SubAdminUpdatePermission
        }
    }>
        {props.children}
    </SubadminContext.Provider>
}
