import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const AttributesContext = createContext()


async function addAttribute(item) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    }

    let config = {
        headers: {
            "Content-Type": "application/json", // Set to application/json
            "Authorization": `Bearer ${token}` // Use Authorization header for the token
        }
    };

    try {
        let result = await axios.post(`${ADMIN_BASE_URL}/attributes`, JSON.stringify(item), config); // Stringify the item
        console.log('Success:', result);
        return { result: "Done" }; // Indicate success
    } catch (error) {
        // Handle error as before
        if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        return { result: "Fail", message: error.message }; // Return error message
    }
}




async function getAttribute(page, itemsPerPage, searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)
    // var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    var result = await rawdata.json()
    console.log('result >>>>>>>>>>>>>>>>>>>>>', result)
    if (result) {
        return { result: "Done", data: result.data }
    }
    else {
        return { result: "Fail", message: "Internal Server Error" }
    }
}
async function getAttributeValueList(_id) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes/value/${_id}`, config2)
    // var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    var result = await rawdata.json()
    console.log('result >>>>>>>>>>>>>>>>>>>>>', result)
    if (result) {
        return { result: "Done", data: result.data }
    }
    else {
        return { result: "Fail", message: "Internal Server Error" }
    }
}

async function addAttributeValue(_id, newAttribute) {

    var item = newAttribute// new FormData();
    // item.append('name', newAttribute.name);
    // item.append('image', newAttribute.image);
    // item.append('sequence_number', newAttribute.sequence_number);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/attributes/value/${_id}`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}





async function getAttributeById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/attributes/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function updateAttribute(_id, attribute) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    }

    let config = {
        headers: {
            "Content-Type": "application/json", // Set to application/json
            "Authorization": `Bearer ${token}` // Use Authorization header for the token
        }
    };

    try {
        let result = await axios.put(`${ADMIN_BASE_URL}/attributes/${_id}`, JSON.stringify(attribute), config); // Stringify the attribute
        console.log('Success:', result);
        return { result: "Done" }; // Indicate success
    } catch (error) {
        // Handle error as before
        if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        return { result: "Fail", message: error.message }; // Return error message
    }
}

async function updateAttributeValue(attribute) {
    let token = localStorage.getItem("token");
    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    }

    let config = {
        headers: {
            "Content-Type": "application/json", // Set to application/json
            "Authorization": `Bearer ${token}` // Use Authorization header for the token
        }
    };

    try {
        let result = await axios.put(`${ADMIN_BASE_URL}/attributes/value/${attribute.item_id}`, JSON.stringify(attribute), config); // Stringify the attribute
        console.log('Success:', result);
        return { result: "Done" }; // Indicate success
    } catch (error) {
        // Handle error as before
        if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        return { result: "Fail", message: error.message }; // Return error message
    }
}



async function deleteAttribute(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes/${item._id}`, config3)
    var result = await rawdata.json()
    return { result: "Done", message: "Attribute is Deleted" }
}




async function attributeStatus(_id, status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        is_active: status
    }

    try {
        var result = await axios.put(`${ADMIN_BASE_URL}/attributes/status/${_id}`, obj, config3)
        console.log('Success:', result);
        if (result) {
            return { result }
        } else {
            return { result: "Fail", message: "Internal Server Error" }
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
    }
}

async function attributeStatusValue(obj) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let bodyObj = {
        is_active: obj.status,
        value_id: obj._id
    }

    try {
        var result = await axios.put(`${ADMIN_BASE_URL}/attributes/value/status/${obj.item_id}`, bodyObj, config3)
        console.log('Success:', result);
        if (result) {
            return { result }
        } else {
            return { result: "Fail", message: "Internal Server Error" }
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
    }
}




async function deleteAttributeValue(obj) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({ "value_id": obj._id }) // Add body here
    };

    console.log(config3);

    try {
        var rawdata = await fetch(`${ADMIN_BASE_URL}/attributes/value/${obj.item_id}`, config3); // Only pass config3 here
        if (!rawdata.ok) {
            throw new Error(`Error: ${rawdata.status} - ${rawdata.statusText}`); // Handle errors
        }
        var result = await rawdata.json();
        console.log(result);
        return { result: "Done", message: "Attribute is Deleted" };
    } catch (error) {
        console.error("Failed to delete attribute:", error);
        return { result: "Error", message: error.message };
    }
}

export default function AttributesContextProvider(props) {
    return <AttributesContext.Provider value={
        {
            addAttribute: addAttribute,
            getAttribute: getAttribute,
            getAttributeValueList: getAttributeValueList,
            getAttributeById: getAttributeById,
            updateAttribute: updateAttribute,
            deleteAttribute: deleteAttribute,
            deleteAttributeValue: deleteAttributeValue,
            attributeStatus: attributeStatus,
            attributeStatusValue: attributeStatusValue,
            addAttributeValue: addAttributeValue,
            updateAttributeValue: updateAttributeValue
        }
    }>
        {props.children}
    </AttributesContext.Provider>
}
