import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { AttributesContext } from '../../store/AttributeContextProvider';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';

export default function ListAttributes() {
    const navigate = useNavigate();
    const [attribute, setAttribute] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const { getAttribute, deleteAttribute, updateAttribute, attributeStatus, addAttribute } = useContext(AttributesContext);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editedAttribute, setEditedAttribute] = useState({});
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [newAttribute, setNewAttribute] = useState({
        attribute_name: '',
        attribute_source: '',
        attribute_type: '',
        sequence_number: '',
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Fetch attribute data from the API
    async function getAPIData(page) {
        const response = await getAttribute(page, itemsPerPage, searchQuery);
        if (response.result === "Done") {
            setAttribute(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteAttribute({ _id: confirmItem._id });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Attribute deleted successfully');
        }
    };

    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = !confirmItem.is_active;
            await attributeStatus(confirmItem._id, newStatus);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Attribute status changed successfully');
        }
    };

    // Handle opening the Edit modal and populating fields
    const handleEditAttribute = (item) => {
        setEditedAttribute(item); // Populate modal with selected attribute
        setEditModalOpen(true);   // Open modal
    };

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setEditedAttribute((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddNewAttribute = async () => {
        // Validation: Check if all fields are filled
        if (!newAttribute.attribute_name || !newAttribute.attribute_type || !newAttribute.sequence_number, !newAttribute.attribute_source) {
            toast.error('Please fill all fields before submitting.');
            return;
        }

        const response = await addAttribute(newAttribute);
        if (response.result === "Done") {
            toast.success('Attribute added successfully');
            getAPIData(currentPage);
            setAddModalOpen(false);
            setNewAttribute({ attribute_source: '', attribute_name: '', attribute_type: '', sequence_number: '' });
        } else {
            toast.error(response.message);
        }
    };

    const handleUpdateAttribute = async () => {
        await updateAttribute(editedAttribute._id, editedAttribute);
        getAPIData(currentPage);
        setEditModalOpen(false);
        toast.success('Attribute updated successfully');
    };

    useEffect(() => {
        getAPIData(currentPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage, searchQuery]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Attribute - List</h3>
                                </div>
                                <Link to="#" className="btn btn-primary pull-right" onClick={() => setAddModalOpen(true)}>Add New Record</Link>
                            </div>
                            <div className="ibox-body">
                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>
                                                <th style={{ width: '5%' }}>Sr No</th>
                                                <th style={{ width: '12%' }}>Attribute Name</th>
                                                <th style={{ width: '12%' }}>Attribute Source</th>
                                                <th style={{ width: '12%' }}>Attribute Type</th>
                                                <th style={{ width: '15%' }}>Sequence No.</th>
                                                <th style={{ width: '10%' }}>Status</th>
                                                <th style={{ width: '40%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attribute.length > 0 ? attribute.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    <td>{item.attribute_source}</td>
                                                    <td>{item.attribute_name}</td>
                                                    <td>{item.attribute_type}</td>
                                                    <td>{item.sequence_number}</td>
                                                    <td>
                                                        {item.is_active ? (
                                                            <span className="badge badge-success">Active</span>
                                                        ) : (
                                                            <span className="badge badge-danger">Inactive</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-info" onClick={() => handleEditAttribute(item)} style={{
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            borderRadius: '10px'
                                                        }}>Edit</span>

                                                        <span className="badge badge-danger" onClick={() => handleDelete(item)} style={{
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            borderRadius: '10px'
                                                        }}>Delete</span>

                                                        <span className="badge badge-primary" onClick={() => navigate(`/attributes-manage/${item._id}`)} style={{
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            borderRadius: '10px'
                                                        }}>Manage Attribute</span>

                                                        <span className="badge badge-secondary" onClick={() => handleStatusChange(item)} style={{
                                                            margin: '5px',
                                                            cursor: 'pointer',
                                                            borderRadius: '10px'
                                                        }}>
                                                            {item.is_active ? 'Deactivate' : 'Activate'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={7}>No Records Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Edit Attribute Modal */}
            {editModalOpen && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Attribute</h5>
                                <button type="button" className="btn-close" onClick={() => setEditModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label>Attribute Source</label>
                                        <select
                                            className="form-control"
                                            name="attribute_source"
                                            value={editedAttribute.attribute_source || ''}
                                            onChange={handleModalInputChange}
                                        >
                                            <option value="">Select Source</option>
                                            <option value="main_attributes">Main Attributes</option>
                                            <option value="custom_attributes">Custom Attributes</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Attribute Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="attribute_name"
                                            value={editedAttribute.attribute_name || ''}
                                            onChange={handleModalInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Attribute Type</label>
                                        <select
                                            className="form-control"
                                            name="attribute_type"
                                            value={editedAttribute.attribute_type || ''}
                                            onChange={handleModalInputChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Textbox">Textbox</option>
                                            <option value="RadioButton">RadioButton</option>
                                            <option value="CheckBox">CheckBox</option>
                                            <option value="Textarea">Textarea</option>
                                            <option value="DropdownList">DropdownList</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Sequence Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="sequence_number"
                                            value={editedAttribute.sequence_number || ''}
                                            onChange={handleModalInputChange}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditModalOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleUpdateAttribute}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Attribute Modal */}
            {addModalOpen && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Attribute</h5>
                                <button type="button" className="btn-close" onClick={() => setAddModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label>Attribute Source</label>
                                        <select
                                            className="form-control"
                                            name="attribute_source"
                                            value={newAttribute.attribute_source}
                                            onChange={(e) => setNewAttribute({ ...newAttribute, attribute_source: e.target.value })}
                                        >
                                            <option value="">Select Source</option>
                                            <option value="main_attributes">Main Attributes</option>
                                            <option value="custom_attributes">Custom Attributes</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Attribute Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="attribute_name"
                                            value={newAttribute.attribute_name}
                                            onChange={(e) => setNewAttribute({ ...newAttribute, attribute_name: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Attribute Type</label>
                                        <select
                                            className="form-control"
                                            name="attribute_type"
                                            value={newAttribute.attribute_type}
                                            onChange={(e) => setNewAttribute({ ...newAttribute, attribute_type: e.target.value })}
                                        >
                                            <option value="">Select</option>
                                            <option value="Textbox">Textbox</option>
                                            <option value="Textbox">RadioButton</option>
                                            <option value="CheckBox">CheckBox</option>
                                            <option value="Textarea">Textarea</option>
                                            <option value="DropdownList">DropdownList</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Sequence Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="sequence_number"
                                            value={newAttribute.sequence_number}
                                            onChange={(e) => setNewAttribute({ ...newAttribute, sequence_number: e.target.value })}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setAddModalOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleAddNewAttribute}>Add Attribute</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Confirmation Modal */}
            {showConfirmModal && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalAction === 'delete' ? 'Delete Confirmation' : 'Status Change Confirmation'}</h5>
                                <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                {modalAction === 'delete' ? (
                                    <p>Are you sure you want to delete this attribute?</p>
                                ) : (
                                    <p>Are you sure you want to change the status of this attribute?</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={modalAction === 'delete' ? confirmDelete : confirmStatusChange}>
                                    {modalAction === 'delete' ? 'Delete' : 'Change Status'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
