import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { PromocodeContext } from '../../store/PromocodeContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function AddPromocode() {


    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { addPromocode } = useContext(PromocodeContext);
    const [formData, setFormData] = useState({
        title: '',
        promocode: '',
        discount_type: '',
        discount_value: '',
        applicable_vendor: '',
        expiry_date: '',
        max_discounted_amount_upto: '',
        min_amount_to_applicable: '',
        description: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        tempErrors.title = formData.title ? "" : "title is required.";
        tempErrors.promocode = formData.promocode ? "" : "promocode is required.";
        tempErrors.expiry_date = formData.expiry_date ? "" : "expiry_date is required.";
        tempErrors.description = formData.description ? "" : "description is required.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };



    function handleChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {



        e.preventDefault();
        if (validate()) {
            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button
        
            try {

                var item = {
                    title: formData.title,
                    promocode: formData.promocode,
                    discount_type: formData.discount_type,
                    discount_value: formData.discount_value,
                    applicable_vendor: formData.applicable_vendor,
                    expiry_date: formData.expiry_date,
                    max_discounted_amount_upto: formData.max_discounted_amount_upto,
                    min_amount_to_applicable: formData.min_amount_to_applicable,
                    description: formData.description
                }

                // alert(JSON.stringify(item))


                const response = await addPromocode(item);
                console.log('responsen Added', response)



                if (response.result.status == 200) {
                    toast.success('Promocode added successfully!');
                    navigate(`/promocode`);
                } else {
                    toast.error('Failed to add Promocode.');
                    setIsLoading(false); // Hide loader
                    setIsButtonDisabled(false); // Enable button
                }
            } catch (error) {
                toast.error('An error occurred while adding the Promocode.');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        } else {
            toast.error('Please fix the errors in the form.');
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Add New Promocode</h3>
                                </div>
                                <Link to="/promocode" className="btn btn-secondary pull-right">
                                    Back
                                </Link>
                            </div>
                            <div className="ibox-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.title && <span className="text-danger">{errors.title}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Promocode</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="promocode"
                                                    value={formData.promocode}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.promocode && <span className="text-danger">{errors.promocode}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Discount Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="discount_type"
                                                    value={formData.discount_type}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.discount_type && <span className="text-danger">{errors.discount_type}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Discount Value</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="discount_value"
                                                    value={formData.discount_value}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.discount_value && <span className="text-danger">{errors.discount_value}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Applicable Vendor</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="applicable_vendor"
                                                    value={formData.applicable_vendor}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.applicable_vendor && <span className="text-danger">{errors.applicable_vendor}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Expiry date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="expiry_date"
                                                    value={formData.expiry_date}
                                                    onChange={handleChange}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Max Discounted Amount</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="max_discounted_amount_upto"
                                                    value={formData.max_discounted_amount_upto}
                                                    onChange={handleChange}

                                                />
                                                {errors.max_discounted_amount_upto && <span className="text-danger">{errors.max_discounted_amount_upto}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Min Amount Applicable</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="min_amount_to_applicable"
                                                    value={formData.min_amount_to_applicable}
                                                    onChange={handleChange}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="description" className="control-label">Description<span className="text-danger">*</span></label>
                                                <textarea id="description" className="form-control" rows={5} name="description" onChange={handleChange} maxLength="100"></textarea>

                                                {errors.description && <small className="text-danger">{errors.description}</small>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary mt-2" disabled={isButtonDisabled}>
                                        {isLoading ? 'AddPromocode Adding...' : 'AddPromocode'} 
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
