import React, { useEffect, useState, useContext } from 'react';
import '../../assets/css/permissions.css';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { SubadminContext } from '../../store/SubadminContextProvider';

export default function Permissions() {
    const [admin, setAdmin] = useState([]);
    const { getpermissions, SubAdminUpdatePermission } = useContext(SubadminContext);
    const [permissions, setPermissions] = useState({});
    const navigate = useNavigate();
    var { _id } = useParams()
    // Function to format the API response to match the permissions structure
    const formatPermissions = (data) => {
        const formattedPermissions = {};
        data.forEach((module) => {
            formattedPermissions[module.module_slug] = []; // Use an array to store the values directly
            module.permissions.forEach((perm) => {
                formattedPermissions[module.module_slug].push(perm.slug);
            });
        });
        return formattedPermissions;
    };

    // Fetch data from the API
    const getAPIData = async () => {
        try {
            const response = await getpermissions();
            console.log('All permission response',response)
            if (response.data && response.data.permissionsData) {
                const formattedPermissions = formatPermissions(response.data.permissionsData);
                setAdmin(response.data.permissionsData);
                setPermissions(formattedPermissions);
            } else {
                alert('Failed to fetch permissions data');
            }
        } catch (error) {
            console.error('Error fetching permissions:', error);
            alert('An error occurred while fetching permissions data');
        }
    };

    // Handle checkbox change to update permissions state
    const handleCheckboxChange = (module, permissionType) => {
        setPermissions((prevPermissions) => {
            const updatedPermissions = { ...prevPermissions };
            if (updatedPermissions[module].includes(permissionType)) {
                // Remove permission if it is already included
                updatedPermissions[module] = updatedPermissions[module].filter((perm) => perm !== permissionType);
            } else {
                // Add permission if it is not included
                updatedPermissions[module].push(permissionType);
            }
            return updatedPermissions;
        });
    };

    // Save the updated permissions
    const savePermissions = async () => {
        try {
            // console.log('Permissions to be saved:', permissions);
            const response = await SubAdminUpdatePermission(permissions,_id);
            // Example of making a request to save permissions
            // await savePermissionsAPI(permissions);
            // alert('Permissions saved successfully!');
        } catch (error) {
            console.error('Error saving permissions:', error);
            alert('Failed to save permissions');
        }
    };

    useEffect(() => {
        getAPIData();
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-body">
                                <div className="permissions-container">
                                    <h2>Manage Permissions for Sub Admin</h2>
                                    <table className="permissions-table">
                                        <thead>
                                            <tr>
                                                <th>Module Name</th>
                                                <th>List</th>
                                                <th>Edit</th>
                                                <th>Add</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(permissions).map((module) => (
                                                <tr key={module}>
                                                    <td>{module.charAt(0).toUpperCase() + module.slice(1)}</td>
                                                    {['list', 'edit', 'add', 'delete'].map((permissionType) => (
                                                        <td key={permissionType}>
                                                            <input
                                                                type="checkbox"
                                                                checked={permissions[module].includes(permissionType)}
                                                                onChange={() => handleCheckboxChange(module, permissionType)}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button className="save-button" onClick={savePermissions}>
                                        Save Permissions
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
