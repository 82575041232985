import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ProductContext } from '../../store/ProductContextProvider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export default function ProductVariantEdit() {

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { getVariantById, updateProductVariant } = useContext(ProductContext);
  let { id } = useParams();
  let navigate = useNavigate();

  const [variantData, setVariantData] = useState({
    product_object_id: '',
    short_description: '',
    long_description: '',
    price: '',
    discount: '',
    sku: '',
    image: '',
    secondary_images: []
  });


  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchVariantData();
  }, []);

  async function fetchVariantData() {
    try {
      const response = await getVariantById(id);
      console.log('CALING getVariantById >>>>>>>>>', response);

      if (response && response.data) {
        setVariantData({
          product_object_id: response.data.product_object_id || '',
          short_description: response.data.short_description || '',
          long_description: response.data.long_description || '',
          price: response.data.price || '',
          discount: response.data.discount || '',
          sku: response.data.sku || '',
          image: response.data.image || '',
          secondary_images: response.data.secondary_images || []
        });
        console.log('variantData>>>>>>>>>>>>>', variantData);

      }
    } catch (error) {
      console.error('Error fetching variant data:', error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariantData({
      ...variantData,
      [name]: value
    });
  };




  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVariantData({ ...variantData, image: URL.createObjectURL(file) });
    }
  };

  const handleSecondaryImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => URL.createObjectURL(file));
    setVariantData({ ...variantData, secondary_images: [...variantData.secondary_images, ...newImages] });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!variantData.price || isNaN(variantData.price)) formErrors.price = "Valid price is required";
    if (variantData.discount && (isNaN(variantData.discount) || variantData.discount < 0 || variantData.discount > 100)) {
      formErrors.discount = "Discount must be between 0 and 100";
    }
    if (!variantData.sku) formErrors.sku = "SKU is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button

    console.log(variantData);
    try {
      const response = await updateProductVariant(variantData, id);
      console.log('response >>>>>>>>>>>>>>>>>>>>>>>>', response)



      if (response && response.status === true) {
        navigate(`/product-variant/${variantData.product_object_id}`);
      } else {
        alert('Error updating variant');
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
      }
    } catch (error) {
      console.error('Error updating variant:', error);
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="page-content fade-in-up">
          <Container fluid>
            <div className="ibox">
              <div className="ibox-head d-flex justify-content-between align-items-center" style={{ minHeight: '122px' }}>
                <div>
                  <h3>Edit Product Variant & Manage your Inventories</h3>

                </div>
                <Link to={`/product-variant/${variantData.product_object_id}`} className="btn btn-secondary">
                  Back to Variant Detail
                </Link>
              </div>

              <div className="ibox-body">
                <Form onSubmit={handleSubmit}>

                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formPrice">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="number"
                          name="price"
                          value={variantData.price}
                          onChange={handleInputChange}
                          isInvalid={!!errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formDiscount">
                        <Form.Label>Discount (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="discount"
                          value={variantData.discount}
                          onChange={handleInputChange}
                          isInvalid={!!errors.discount}
                          maxLength={2}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.discount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formSKU">
                        <Form.Label>SKU</Form.Label>
                        <Form.Control
                          type="text"
                          name="sku"
                          value={variantData.sku}
                          onChange={handleInputChange}
                          isInvalid={!!errors.sku}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sku}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="formShortDescription">
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="short_description"
                          value={variantData.short_description}
                          onChange={handleInputChange}
                          isInvalid={!!errors.short_description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.short_description}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="formLongDescription">
                        <Form.Label>Long Description</Form.Label>
                        <ReactQuill
                          value={variantData.long_description}
                          onChange={(value) => setVariantData({ ...variantData, long_description: value })}
                          modules={{
                            toolbar: [
                              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                              ['bold', 'italic', 'underline'],
                              [{ 'align': [] }],
                              ['link', 'image'],
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline',
                            'align', 'link', 'image'
                          ]}
                          style={{ height: '200px' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br /> <br />
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formImage">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                          type="file"
                          name="image"
                          onChange={handleImageChange}
                        />
                        {variantData.image && (
                          <div className="mt-3">
                            <img
                              src={variantData.image}
                              alt="Variant"
                              style={{ maxHeight: '150px', objectFit: 'cover' }}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="formSecondaryImages">
                        <Form.Label>Secondary Images</Form.Label>
                        <Form.Control
                          type="file"
                          name="secondary_images"
                          multiple
                          onChange={handleSecondaryImagesChange}
                        />
                        {variantData.secondary_images.length > 0 && (
                          <div className="mt-3 d-flex flex-wrap">
                            {variantData.secondary_images.map((image, i) => (
                              <img
                                key={i}
                                src={image}
                                alt={`Secondary ${i + 1}`}
                                style={{ maxHeight: '70px', maxWidth: '70px', objectFit: 'cover', marginRight: '10px' }}
                                className="rounded border"
                              />
                            ))}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button type="submit" variant="primary" disabled={isButtonDisabled}>
                    {isLoading ? 'Variant Updating...' : 'Update Variant'}
                  </Button>
                </Form>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}
