import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListSubCategories from './ListSubCategories';
import AddSubCategories from './AddSubCategories';
import EditSubCategories from './EditSubCategories';

export default function SubCategoriesRoutes() {
  return (
    <Routes>
      <Route path='/subcategory' element={<ListSubCategories/>} />
      <Route path='/subcategory-add' element={<AddSubCategories/>} />
      <Route path='/subcategory-edit' element={<EditSubCategories/>} />
   
    </Routes>
  )
}
