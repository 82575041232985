import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListReturnTypes from './ListReturnTypes';
import AddReturnTypes from './AddReturnTypes';
import EditReturnTypes from './EditReturnTypes';

export default function ReturnTypeRoutes() {
  return (
    <Routes>
    <Route path='/returntypes' element={<ListReturnTypes/>} />
    <Route path='/returntypes-add' element={<AddReturnTypes/>}/>
    <Route path='/returntypes-edit/:_id' element={<EditReturnTypes/>}/>
    
  </Routes>
  )
}
