import React from 'react'
import { Routes, Route } from 'react-router-dom';
import SubAdminList from './SubAdminList';
import SubAdminAdd from './SubAdminAdd';
import EditSubAdmin from './EditSubAdmin';
import Permissions from './Permissions';


export default function SubadminRoutes() {
  return (
    <Routes>
      <Route path='/subadmin-list' element={<SubAdminList/>} /> 
      <Route path='/subadmin-add' element={<SubAdminAdd/>} /> 
      <Route path='/subadmin-edit/:_id' element={<EditSubAdmin/>} /> 
      <Route path='/permissions/:_id' element={<Permissions/>} /> 
    </Routes>
  )
}
