import React from 'react'
import { Routes,Route } from 'react-router-dom';
import AddCustomer from './AddCustomer';
import ListCustomer from './ListCustomer';
import EditCustomer from './EditCustomer';
export default function CustomerRoutes() {
    return (
        <Routes>
            <Route path='/customer' element={<ListCustomer/>}/>
            <Route path='/customer-add' element={<AddCustomer />} />
            <Route path='edit-Customer/:_id' element={<EditCustomer/>}/>
        </Routes>
    )
}