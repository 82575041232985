import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListPromocode from './ListPromocode';
import AddPromocode from './AddPromocode';
import EditPromocode from './EditPromocode';

export default function PromocodeRoutes() {
  return (
    <Routes>
    <Route path='/promocode' element={<ListPromocode/>} />
    <Route path='/promocode-add' element={<AddPromocode/>}/>
    <Route path='/promocode-edit/:_id' element={<EditPromocode/>}/>
    
  </Routes>
  )
}