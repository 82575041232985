import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductList from './ProductList';
import ProductAdd from './ProductAdd';
import ProductVariantAdd from './ProductVariantAdd';
import ProductVariant from './ProductVariant';
import ProductVariantEdit from './ProductVariantEdit';
import ProductEdit from './ProductEdit';
import ProductAttributesAdd from './ProductAttributesAdd';
import ProductAttributesManage from './ProductAttributesManage';

export default function ProductRoutes() {
  return (
    <Routes>
      <Route path='/product-list' element={<ProductList />} />
      <Route path='/product-add' element={<ProductAdd />} />
      <Route path='/product-variant-add/:id' element={<ProductVariantAdd />} />
      <Route path='/product-variant/:id' element={<ProductVariant />} />
      <Route path='/product-variant-edit/:id' element={<ProductVariantEdit />} />
      <Route path='/edit-product/:_id' element={<ProductEdit />} />
      <Route path='/product-attributes-add/:id' element={<ProductAttributesAdd />} />
      <Route path='/product-attributes-manage/:id' element={<ProductAttributesManage />} />
    </Routes>
  );
}
