import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const CustomerContext = createContext()


async function addCustomer(customer) {
console.log('customer++++++++++++',customer);

    var item = new FormData();
    item.append('firstname', customer.firstname);
    item.append('lastname', customer.lastname);
    item.append('email', customer.email);
    item.append('country_code', customer.country_code);
    item.append('mobile_no', customer.mobile_no);
    item.append('gender', customer.gender);
    item.append('dob', customer.dob);
    item.append('address[street]', customer.addressStreet);
    item.append('address[city]', customer.addressCity);
    item.append('address[state]', customer.addressState);
    item.append('address[postal_code]', customer.addressPostal_code);
    item.append('referred_by', customer.referred_by);
    item.append('image', customer.image);


    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/users`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function getCustomer(page,itemsPerPage,searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },
       
    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/users?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)
    // var rawdata = await fetch(`${ADMIN_BASE_URL}/customers?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    var result = await rawdata.json()

    if (result)
        return { result: "Done", data: result.data }
    else
        return { result: "Fail", message: "Internal Server Error" }
}




async function getCustomerById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/users/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function customerUpdate(customer, _id) {

    var item = new FormData();
    item.append('firstname', customer.firstname);
    item.append('lastname', customer.lastname);
    item.append('email', customer.email);
    item.append('country_code', customer.country_code);
    item.append('mobile_no', customer.mobile_no);
    item.append('gender', customer.gender);
    item.append('dob', customer.dob);
    item.append('address[street]', customer.addressStreet);
    item.append('address[city]', customer.addressCity);
    item.append('address[state]', customer.addressState);
    item.append('address[postal_code]', customer.addressPostal_code);
    item.append('referred_by', customer.referred_by);
    item.append('image', customer.image);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/users/${_id}`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}



async function deleteCustomer(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/users/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Customer is Deleted" }
}



async function customerStatus(_id,status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        status : status
    }
    var result = await axios.put(`${ADMIN_BASE_URL}/users/status/${_id}`,obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Customer is Deleted" }
}



export default function CustomerContextProvider(props) {
    return <CustomerContext.Provider value={
        {
            addCustomer: addCustomer,
            getCustomer: getCustomer,
            getCustomerById: getCustomerById,
            customerUpdate: customerUpdate,
            deleteCustomer: deleteCustomer,
            customerStatus: customerStatus
        }
    }>
        {props.children}
    </CustomerContext.Provider>
}
