import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {ReturnTypesContext } from '../../store/ReturnTypesContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function AddReturnTypes() {
    const { addReturnTypes } = useContext(ReturnTypesContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [formData, setFormData] = useState({
        return_type: '',
        description: '',
        
   
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        tempErrors.return_type = formData.return_type ? "" : "return_type is required.";
        tempErrors.description = formData.description ? "" : "description is required.";




        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };



    function handleChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {



        e.preventDefault();
        if (validate()) {
            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button
            try {

                var item = {
                    return_type: formData.return_type,
                    description: formData.description,
                   
                    
                }
// console.log(item)
// return


                const response = await addReturnTypes(item);
                console.log('response',response)


                if (response.result.status == 200) {
                    toast.success('ReturnTypes added successfully!');
                    navigate(`/returntypes`);
                } else {
                    toast.error('Failed to add ReturnTypes.');
                    setIsLoading(false); // Hide loader
                    setIsButtonDisabled(false); // Enable button
                }
            } catch (error) {
                toast.error('An error occurred while adding the ReturnTypes.');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        } else {
            toast.error('Please fix the errors in the form.');
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Add New ReturnTypes</h3>
                                </div>
                            </div>
                            <div className="ibox-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Return Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="return_type"
                                                    value={formData.return_type}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.return_type && <span className="text-danger">{errors.return_type}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.description && <span className="text-danger">{errors.description}</span>}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary mt-2"  disabled={isButtonDisabled}>
                                        {isLoading ? 'ReturnTypes Adding...' : 'Add ReturnTypes'}   
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
