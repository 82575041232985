import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { AllContext } from '../../store/AllContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';

export default function ContactUs() {
 
    const [contactus, setContactus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10); // Change this value as needed
    const [searchQuery, setSearchQuery] = useState('');
    const { getcontactus } = useContext(AllContext);

    // Modal state management

    // Get all data
    async function getAPIData(page) {
        const response = await getcontactus(page, itemsPerPage, searchQuery);
        console.log("response",response)
        if (response.result === "Done") {
            setContactus(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

  
    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };

    useEffect(() => {
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Contactus - List</h3>
                                </div>
                               
                            </div>
                            <div className="ibox-body">
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search"
                                                type="text" placeholder="Search ..." value={searchQuery}
                                                onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>
                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>
                                                <th>Sr No</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Contact no</th>
                                                <th>company</th>
                                                <th>Status</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contactus.length > 0 ? contactus.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    <td>{item.name} {item.lastname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.contactno}</td>
                                                    <td>{item.company}</td>
                                                    <td>{item.status}</td>
                                                  
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={7}>No Records Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Modal for Confirmation */}
            
        </>
    );
}
