import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { ReturnTypesContext } from '../../store/ReturnTypesContextProvider';
import 'react-toastify/dist/ReactToastify.css';

export default function ListReturnTypes() {
   
    const { getReturnTypes, deleteReturnTypes, ReturnTypesStatus } = useContext(ReturnTypesContext);
    const [ReturnTypes, setFaqs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [permissions, setPermissions] = useState({});
  
    //getAllData
    async function getAPIData(page) {
        const response = await getReturnTypes(page, itemsPerPage, searchQuery);
        if (response.status) {
            setFaqs(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }
  
    // Handle delete action
    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };
  
    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteReturnTypes({ _id: confirmItem._id });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('ReturnTypes deleted successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
  
    // Handle status change
    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };
  
    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_active ? false : true;
            await ReturnTypesStatus(confirmItem._id, newStatus);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('ReturnTypes status updated successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
  
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };
  
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
  
  
  
    
    useEffect(() => {
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);
  
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>ReturnTypes - List</h3>
                                </div>
                                <Link to="/returntypes-add" className="btn btn-primary pull-right">Add New Record</Link>
                            </div>
                            <div className="ibox-body">
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search"
                                                type="text" placeholder="Search ..." value={searchQuery}
                                                onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>
                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}>Sr No.</th>
                                                <th style={{ width: '10%' }}>Return Type</th>
                                                <th style={{ width: '20%' }}>description</th>
                                                <th>Status</th>
                                                <th style={{ width: '15%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ReturnTypes.length > 0 ? (
                                                ReturnTypes.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        <td>{item.return_type || 'N/A'}</td>
                                                        <td>{item.description || 'N/A'}</td>
                                                        <td>
                                                          {item.is_active === true? (
                                                              <span className="badge badge-success">Active</span>
                                                          ) : (
                                                              <span className="badge badge-danger">Inactive</span>
                                                          )}
                                                      </td>
                                                        <td className="text-center">
                                                           
  
                                                            {/* <span className="badge badge-primary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => window.location.href = `/subadmin-edit/${subAdmin._id}`}>
                                                                Edit Sub Admin
                                                            </span> */}
  
                                                            <Link
                                                                to={`/returntypes-edit/${item._id}`}
                                                                className="badge badge-primary badge-pill"
                                                                style={{
                                                                    display: 'block',
                                                                    marginBottom: '5px',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none' // Added style for removing underline
                                                                }}
                                                            >
                                                                Edit ReturnTypes
                                                            </Link>
  
                                                            <span className="badge badge-danger badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)}>
                                                                Delete ReturnTypes
                                                            </span>
  
                                                            {item.is_active ? (
                                                                <span className="badge bg-warning text-dark badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                    Deactivate Status
                                                                </span>
                                                            ) : (
                                                                <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                    Activate Status
                                                                </span>
                                                            )}
                                                        </td>
  
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7">No records found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
  
            {/* Modal for Confirmations */}
            <div className={`modal fade ${showConfirmModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalAction === 'delete' ? 'Confirm Sub Admin Deletion' : 'Confirm Status Change'}</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalAction === 'delete' ? 'Are you sure you want to delete this Sub Admin?' : 'Are you sure you want to update the status?'}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={modalAction === 'delete' ? confirmDelete : confirmStatusChange}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
  
       
      
        </>
    );
  }
  