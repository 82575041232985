import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { AttributesContext } from '../../store/AttributeContextProvider';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';


export default function ManageAttributes() {
    const navigate = useNavigate();
    const { _id } = useParams();
    const [attributeValue, setAttributeValue] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const { deleteAttributeValue, updateAttributeValue, addAttributeValue, attributeStatusValue, getAttributeValueList } = useContext(AttributesContext);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false); // For Add Modal
    const [editedAttribute, setEditedAttribute] = useState({});
    const [newAttribute, setNewAttribute] = useState({}); // For new attribute
    const [newImage, setNewImage] = useState(null); // To store image file

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Fetch attributeValue data from the API
    async function getAPIData(page) {
        const response = await getAttributeValueList(_id);
        if (response.result === "Done") {
            setAttributeValue(response.data.docs);
            setTotalPages(response.data.totalPages);
        } else {
            alert(response.message);
        }
    }

    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteAttributeValue({ _id: confirmItem._id, item_id: _id });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Attribute deleted successfully');
        }
    };

    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = !confirmItem.isActive;
            let obj = {
                _id: confirmItem._id,
                item_id: _id,
                status: newStatus
            }
            await attributeStatusValue(obj);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Attribute status changed successfully');
        }
    };

    // Handle opening the Edit modal and populating fields
    const handleEditAttribute = (item) => {
        setEditedAttribute(item); // Populate modal with selected attributeValue
        setEditModalOpen(true);   // Open modal
    };

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setEditedAttribute((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateAttribute = async () => {
        let obj = {
            value_id: editedAttribute._id,
            name: editedAttribute.name,
            sequence_number: editedAttribute.sequence_number,
            item_id: _id

        }
        await updateAttributeValue(obj);
        getAPIData(currentPage);
        setEditModalOpen(false);
        toast.success('Attribute updated successfully');
    };

    // Handle opening Add modal
    const handleAddNewAttribute = () => {
        setNewAttribute({}); // Reset new attribute form
        setNewImage(null);   // Reset file input
        setAddModalOpen(true);  // Open Add modal
    };

    const handleAddModalInputChange = (e) => {
        const { name, value } = e.target;
        setNewAttribute((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]); // Save the selected image file
    };

    const handleAddNewValue = async () => {
        const formData = new FormData();
        formData.append('name', newAttribute.name || '');
        formData.append('sequence_number', newAttribute.sequence_number || '');
        formData.append('image', newImage); // Append the image file to the form data

        await addAttributeValue(_id, formData); // Modify the backend function to accept FormData
        getAPIData(currentPage);
        setAddModalOpen(false);
        toast.success('New attribute value added successfully');
    };

    useEffect(() => {
        getAPIData(currentPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage, searchQuery]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Attribute Name: <b>'{attributeValue.length > 0 && attributeValue[0].attribute_name}'</b></h3>
                                </div>
                                <Link to="/attributes" className="btn btn-secondary pull-right">Back</Link>
                                <button className="btn btn-primary pull-right" onClick={handleAddNewAttribute}>Add New Value</button>
                            </div>
                            <div className="ibox-body">
                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>

                                                <th style={{ width: '8%' }}>Sr No</th>
                                                {attributeValue.length > 0 && attributeValue[0].attribute_name?.toLowerCase() == 'color' && (
                                                    <th style={{ width: '15%' }}>Image</th> // Conditional column for Images
                                                )}
                                                <th style={{ width: '25%' }}>Attribute Name</th>
                                                <th style={{ width: '12%' }}>Sequence No</th>

                                                <th style={{ width: '10%' }}>Status</th>
                                                <th style={{ width: '30%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attributeValue.length > 0 && attributeValue[0].attributes_values?.length > 0 ? (
                                                attributeValue[0].attributes_values.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        {attributeValue[0].attribute_name?.toLowerCase() === 'color' && (
                                                            <td>
                                                                {item.image ? (
                                                                    <img
                                                                        src={item.image} // Display the image
                                                                        alt="Color Image"
                                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                                    />
                                                                ) : (
                                                                    'No Image'
                                                                )}
                                                            </td>
                                                        )}
                                                        <td>{item.name}</td>
                                                        <td>{item.sequence_number}</td>

                                                        <td>
                                                            {item.isActive ? (
                                                                <span className="badge badge-success">Active</span>
                                                            ) : (
                                                                <span className="badge badge-danger">Inactive</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="badge badge-info"
                                                                onClick={() => handleEditAttribute(item)}
                                                                style={{
                                                                    margin: '5px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '10px',
                                                                }}
                                                            >
                                                                Edit
                                                            </span>

                                                            <span
                                                                className="badge badge-danger"
                                                                onClick={() => handleDelete(item)}
                                                                style={{
                                                                    margin: '5px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '10px',
                                                                }}
                                                            >
                                                                Delete
                                                            </span>

                                                            <span
                                                                className="badge badge-secondary"
                                                                onClick={() => handleStatusChange(item)}
                                                                style={{
                                                                    margin: '5px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '10px',
                                                                }}
                                                            >
                                                                {item.isActive ? 'Deactivate' : 'Activate'}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7}>No Records Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Add New Value Modal */}
            {addModalOpen && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Attribute Value</h5>
                                <button type="button" className="btn-close" onClick={() => setAddModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label>Attribute Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={newAttribute.name || ''}
                                            onChange={handleAddModalInputChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Sequence Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="sequence_number"
                                            value={newAttribute.sequence_number || ''}
                                            onChange={handleAddModalInputChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Color Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            onChange={handleImageChange} // File handler for image upload
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setAddModalOpen(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleAddNewValue}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Modal */}
            {editModalOpen && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Attribute Value</h5>
                                <button type="button" className="btn-close" onClick={() => setEditModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label>Attribute Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={editedAttribute.name || ''}
                                            onChange={handleModalInputChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Sequence Number</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="sequence_number"
                                            value={editedAttribute.sequence_number || ''}
                                            onChange={handleModalInputChange}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditModalOpen(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleUpdateAttribute}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Confirm Modal */}
            {showConfirmModal && (
                <div className={`modal fade show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalAction === 'delete' ? 'Delete Confirmation' : 'Status Change Confirmation'}
                                </h5>
                                <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                {modalAction === 'delete' ? (
                                    <p>Are you sure you want to delete this attribute?</p>
                                ) : (
                                    <p>Are you sure you want to change the status of this attribute?</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>No</button>
                                <button type="button" className="btn btn-danger" onClick={modalAction === 'delete' ? confirmDelete : confirmStatusChange}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
