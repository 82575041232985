import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="page-footer text-center" style={{ textAlign: 'center' }}>
            <div className="font-13 text-center" style={{ textAlign: 'center' }}>
                Copyright © 2024 <b>Kaleen e-commerce Website</b> - All Rights Reserved.
                Designed & Developed By <Link to="https://redfaris.com/"><b><i>Redfaris Technologies.</i></b></Link>
            </div>
            <div className="to-top" style={{ textAlign: 'center' }}>
                <i className="fa fa-angle-double-up"></i>
            </div>
        </footer>
    );
}

export default Footer;
