import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListFaqs from './ListFaqs';
import AddFaqs from './AddFaqs';
import EditFaqs from './EditFaqs';

export default function FaqsRoutes() {
  return (
    <Routes>
    <Route path='/faqs' element={<ListFaqs/>} />
    <Route path='/faqs-add' element={<AddFaqs/>} />
    <Route path='/edit-faqs/:_id' element={<EditFaqs/>}/>
  </Routes>
  )
}
