import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function EditProductModal({ show, handleClose, product, handleUpdate }) {
    const [productName, setProductName] = useState(product.product_name);
    const [category, setCategory] = useState(product.category.name);
    const [subCategory, setSubCategory] = useState(product.sub_category.name);

    const handleSubmit = () => {
        const updatedProduct = {
            ...product,
            product_name: productName,
            category: { ...product.category, name: category },
            sub_category: { ...product.sub_category, name: subCategory },
        };
        handleUpdate(updatedProduct);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formProductName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCategory">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            type="text"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSubCategory">
                        <Form.Label>Sub Category</Form.Label>
                        <Form.Control
                            type="text"
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditProductModal;
