import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { FaqsContext } from '../../store/FaqsContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function AddFaqs() {
    const { addFaqs } = useContext(FaqsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [formData, setFormData] = useState({
        question: '',
        answer: '',
        user_type: '',
   
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        tempErrors.question = formData.question ? "" : "Question is required.";
        tempErrors.answer = formData.answer ? "" : "Answer is required.";
        tempErrors.user_type = formData.user_type ? "" : "User_type is required.";



        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };



    function handleChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {



        e.preventDefault();
        if (validate()) {
            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button
            try {

                var item = {
                    question: formData.question,
                    answer: formData.answer,
                    user_type: formData.user_type,
                    
                }
// console.log(item)
// return


                const response = await addFaqs(item);
                console.log('response',response)


                if (response.result.status == 200) {
                    toast.success('Faqs added successfully!');
                    navigate(`/faqs`);
                } else {
                    toast.error('Failed to add Faqs.');
                    setIsLoading(false); // Hide loader
                    setIsButtonDisabled(false); // Enable button
                }
            } catch (error) {
                toast.error('An error occurred while adding the Faqs.');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        } else {
            toast.error('Please fix the errors in the form.');
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Add New Faqs</h3>
                                </div>
                            </div>
                            <div className="ibox-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Question</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="question"
                                                    value={formData.question}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.question && <span className="text-danger">{errors.question}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Answer</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="answer"
                                                    value={formData.answer}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.answer && <span className="text-danger">{errors.answer}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>User Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="user_type"
                                                    value={formData.user_type}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.user_type && <span className="text-danger">{errors.user_type}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary mt-2"  disabled={isButtonDisabled}>
                                        {isLoading ? 'Faqs Adding...' : 'Add Faqs'}   
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
