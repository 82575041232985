import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Col, Row, Badge } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ProductContext } from '../../store/ProductContextProvider';
import '../../assets/css/ProductAttributesAdd.css'; // Import custom styles
import { FaTrash, FaBan } from 'react-icons/fa'; // Importing icons for delete and disable actions
import { ToastContainer, toast } from 'react-toastify';
export default function ProductAttributesManage() {
    const [finalSellingPrice, setFinalSellingPrice] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [product, setProduct] = useState({});
    const [errors, setErrors] = useState({}); // Validation errors
    const [applyDefault, setApplyDefault] = useState(false);

    const { getProductVariants, updateProductVariants } = useContext(ProductContext);
    let navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getProductVariantData();
    }, []);

    async function getProductVariantData() {
        try {
            const response = await getProductVariants(id);
            console.log('variant response >>>>>>>>>>>>>>>>', response);
            if (response && response.data) {
                setProduct(response.data);
                // calculateFinalSellingPrice(response.data);
            }
        } catch (error) {
            console.error('Error fetching attributes:', error);
        }
    }

    const handleKeyDown = (event) => {
        // Allow only numbers (0-9) and prevent other characters
        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    const handleDelete = (index) => {
        // Implement delete functionality
        console.log(`Delete row ${index}`);
    };

    const handleDisable = (index) => {
        // Implement disable functionality
        console.log(`Disable row ${index}`);
    };

    const handleInputChange = (index, field, value) => {

        setProduct((prevProduct) => {
            const updatedVariants = [...prevProduct.variants];
            updatedVariants[index] = {
                ...updatedVariants[index],
                [field]: value,
            };

            const mrp = parseFloat(updatedVariants[index]?.price);
            const discount = parseFloat(updatedVariants[index]?.discount);
            const gst = parseFloat(updatedVariants[index]?.gst);
            console.log({
                mrp, discount, gst
            });

            // Calculate the final selling price based on MRP, Discount, and GST
            const sellingPrice = mrp
                - (mrp * discount / 100)
                + (mrp * gst / 100);

            // Update the selling price in the state
            updatedVariants[index].sellingPrice = sellingPrice.toFixed(2); // rounded to 2 decimal places
            setFinalSellingPrice((prev) => ({
                ...prev,
                [index]: sellingPrice.toFixed(2),
            }));

            return {
                ...prevProduct,
                variants: updatedVariants,
            };
        });
        // Real-time validation for price and stock
        // validateInput(index, field, value);
    };
    const defaultValues = {
        price: '1000',
        discount: '10',
        gst: '18',
        stock: '50'
    };
    const handleDefaultCheckboxChange = (checked) => {
        setApplyDefault(checked);
        if (checked) {
            console.log(product);

            // Update all variants with default values
            const updatedProduct = {
                ...product,
                variants: product.variants.map(variant => ({
                    ...variant,
                    price: product.variants[0].price,
                    discount: product.variants[0].discount,
                    gst: product.variants[0].gst,
                    stock: product.variants[0].stock,
                })),
            };
            calculateFinalSellingPrice(updatedProduct)
            setProduct(updatedProduct);
        }
    };
    const validateInput = (index, field, value) => {
        let newErrors = { ...errors };

        if (field === 'price' || field === 'stock') {
            if (parseFloat(value) <= 0 || isNaN(value)) {
                newErrors[`${field}-${index}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} must be greater than 0`;
            } else {
                delete newErrors[`${field}-${index}`];
            }
        }

        setErrors(newErrors);
    };

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = [];

        product.variants.forEach((variant, index) => {
            if (parseFloat(variant.price) <= 0 || isNaN(variant.price)) {
                formIsValid = false;
                newErrors.push(`Price must be greater than 0 for variant ${index + 1}`);
            }
            if (parseFloat(variant.stock) <= 0 || isNaN(variant.stock)) {
                formIsValid = false;
                newErrors.push(`Stock must be greater than 0 for variant ${index + 1}`);
            }
        });

        setErrors(newErrors);
        return formIsValid;
    };

    const calculateFinalSellingPrice = (product) => {
        const sellingPrices = {};
        if (product.variants && product.variants.length > 0) {
            product.variants.forEach((item, index) => {
                const mrp = parseFloat(item.price || 0);
                const discount = parseFloat(item.discount || 0);
                const gst = parseFloat(item.gst || 0);

                // Calculate the final selling price based on MRP, Discount, and GST
                const sellingPrice = mrp - (mrp * discount / 100) + (mrp * gst / 100);
                sellingPrices[index] = sellingPrice.toFixed(2); // rounded to 2 decimal places
            });
        }

        setFinalSellingPrice(sellingPrices);
    };

    async function postData(e) {
        e.preventDefault();
        setIsLoading(true);
        setIsButtonDisabled(true);

        if (!validateForm()) {
            toast.error('Please fix validation errors before submitting.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            //   alert("Please fix validation errors before submitting.");
            setIsLoading(false);
            setIsButtonDisabled(false);
            return;
        }

        // Creating the payload for the variants
        const payload = {
            product_id: product.product_id, // Include any necessary product details
            variants: product.variants.map((variant, index) => ({
                id: variant._id, // Assuming each variant has a unique ID
                price: parseFloat(variant.price),
                discount: parseFloat(variant.discount),
                gst: parseFloat(variant.gst),
                stock: parseInt(variant.stock),
                sellingPrice: parseFloat(finalSellingPrice[index]), // Final calculated price
                attributes: variant.attributes, // Including any additional attributes
            })),
        };
        console.log('payload>????????????', payload);
        try {
            // Assuming updateProductVariants is a function in context to send the payload to the backend
            const response = await updateProductVariants(payload, id);
            console.log('response???????????', response);
            if (response.result === 'Done') {
                localStorage.setItem('toast', 'Product variant has been updated successfully');
                navigate("/product-list");
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.error('Error updating variants:', error);
        }

        setIsLoading(false);
        setIsButtonDisabled(false);
    }
    return (
        <div className="page-wrapper">
            <Header />
            <ToastContainer />
            <Sidebar />

            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <div className="ibox">
                        <div className="ibox-head">
                            <div className="ibox-title">
                                <h3 className="text-primary">Product Attributes Manage</h3>
                            </div>
                            <Link to="/product-list" className="btn btn-secondary pull-right">
                                Back to Products
                            </Link>
                        </div>
                        <div className="ibox-body">
                            <div className="card shadow mb-4">
                                <div className="card-header bg-success text-white">Product Info</div>
                                <div className="card-body">
                                    <Row className="mb-3 align-items-center">
                                        <Col md={8}>
                                            <h4 className="font-weight-bold">{product.product_name}</h4>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge pill bg="info" className="me-2">SKU: {product.sku}</Badge>
                                                    {product.category && (
                                                        <Badge pill bg="warning" className="me-2">
                                                            Category: {product.category.name}
                                                        </Badge>
                                                    )}
                                                    {product.sub_category && (
                                                        <Badge pill bg="warning" className="me-2">
                                                            Sub Category: {product.sub_category.name}
                                                        </Badge>
                                                    )}
                                                    {product.product_id && (
                                                        <Badge pill bg="primary" className="me-2">
                                                            Product Id: {product.product_id}
                                                        </Badge>
                                                    )}
                                                    {product.gst && (
                                                        <Badge pill bg="info" className="me-2">
                                                            <b>GST</b>: {product.gst}%
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="text-end">
                                            {product.image && (
                                                <div className="mb-2">
                                                    <img
                                                        src={product.image}
                                                        alt="Existing"
                                                        className="img-thumbnail"
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <Form onSubmit={postData} className="mt-4 p-4 rounded shadow">
                                <h4 className="mb-4 text-center">Manage Product Attributes</h4>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg text-center">
                                            <tr>
                                                <th style={{ width: '5%' }}>#</th>
                                                <th style={{ width: '25%', textAlign: 'left' }}>Product Attributes</th>
                                                <th style={{ width: '10%' }}>Price (₹)</th>
                                                <th style={{ width: '12%' }}>Discount (%)</th>
                                                <th style={{ width: '10%' }}>GST(%)</th>
                                                <th style={{ width: '10%' }}>Avail. Stock</th>
                                                <th style={{ width: '13%' }}>Final Price ₹</th>
                                                <th style={{ width: '5%' }}>Make Default  <input
                                                    type="checkbox"
                                                    checked={applyDefault}
                                                    onChange={(e) => handleDefaultCheckboxChange(e.target.checked)}
                                                /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product && product.variants && product.variants.length > 0 ? (
                                                product.variants.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {item.attributes ? (
                                                                Object.entries(item.attributes).map(([key, attr], attrIndex) => (
                                                                    <span key={attrIndex}>
                                                                        <b>{key}</b>: {attr.value}<br />
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                'No attributes available'
                                                            )}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter MRP"
                                                                value={item.price}
                                                                onKeyDown={handleKeyDown}
                                                                onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Discount"
                                                                maxLength={2}
                                                                value={item.discount}
                                                                onKeyDown={handleKeyDown}
                                                                onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter GST"
                                                                value={item.gst}
                                                                onKeyDown={handleKeyDown}
                                                                onChange={(e) => handleInputChange(index, 'gst', e.target.value)}
                                                                maxLength={2}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Available Stock"
                                                                value={item.stock}
                                                                onKeyDown={handleKeyDown}
                                                                onChange={(e) => handleInputChange(index, 'stock', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <span>{finalSellingPrice[index] || 0.00}</span>
                                                        </td>

                                                        {/* <td>
                                                            <div className="d-flex justify-content-around">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link text-danger"
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link text-warning"
                                                                    onClick={() => handleDisable(index)}
                                                                >
                                                                    <FaBan />
                                                                </button>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">No variants available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    disabled={isButtonDisabled}
                                >
                                    {isLoading ? 'Loading...' : 'Save Changes'}
                                </Button>
                            </Form>
                            <br />
                            {/* Display all errors in one place */}
                            {errors.length > 0 && (
                                <p className="text-danger text-center">
                                    {errors.map((error, index) => (
                                        <span key={index}>{error}<br /></span>
                                    ))}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
