import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListAttributes from './ListAttributes';
import ManageAttributes from './ManageAttributes';

export default function AttributeRoutes() {
    return (
        <Routes>
            <Route path='/attributes' element={<ListAttributes />} />
            <Route path='/attributes-manage/:_id' element={<ManageAttributes />} />

        </Routes>
    );
}
