import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Auth from '../../utils/auth';
import Login from '../Login/Login';
import { useNavigate } from 'react-router-dom';



export default function DashboardRoutes() {
  const Navigate = useNavigate()
  return (
    <Routes>
        <Route path="/dashboard" element={<Auth element={Dashboard} />} />
        {/* <Route path='/admin-home' element={!(localStorage.getItem("login"))?<Login/>:localStorage.getItem("role")==="User"?<Profile/>:<AdminHome />} /> */}
    </Routes>
  );
}
