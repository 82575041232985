import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const CategoryContext = createContext()


async function addCategory(category) {

    // var item = new FormData();
    // item.append('name', category.name);
    // item.append('parent_id', null);
    // item.append('image', category.image);
    // item.append('description', category.description);
    // for (let pair of category.entries()) {
    //     console.log(pair[0] + '------------------', pair[1]);
    // }
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/categories`, category, config);
            console.log('Success:', result);
            if (result)
                return { result: result.data }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function getCategory(page, itemsPerPage, searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/categories?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)

    var result = await rawdata.json()

    if (result)
        return { result: "Done", data: result.data }
    else
        return { result: "Fail", message: "Internal Server Error" }
}




async function getCategoryById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/categories/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function categoryUpdate(category, _id) {
    // var item = new FormData();

    // // Append all fields from the category object, ensuring that optional fields are handled
    // if (category.name) {
    //     item.append('name', category.name);
    // }

    // if (category.parent_id !== null && category.parent_id !== undefined) {
    //     item.append('parent_id', category.parent_id);
    // }

    // if (category.image) {
    //     item.append('image', category.image);
    // }

    // if (category.description) {
    //     item.append('description', category.description);
    // }

    // if (category.meta_title) {
    //     item.append('meta_title', category.meta_title);
    // }

    // if (category.meta_keyword) {
    //     item.append('meta_keyword', category.meta_keyword);
    // }

    // if (category.meta_description) {
    //     item.append('meta_description', category.meta_description);
    // }

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/categories/${_id}`, category, config);
            console.log('Success:', result);
            if (result) {
                return { result };
            } else {
                return { result: "Fail", message: "Internal Server Error" };
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}



async function deleteCategory(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/categories/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Category is Deleted" }
}



async function categoryStatus(_id, status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        status: status
    }
    var result = await axios.put(`${ADMIN_BASE_URL}/categories/status/${_id}`, obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Category is Deleted" }
}



export default function CategoryContextProvider(props) {
    return <CategoryContext.Provider value={
        {
            addCategory: addCategory,
            getCategory: getCategory,
            getCategoryById: getCategoryById,
            categoryUpdate: categoryUpdate,
            deleteCategory: deleteCategory,
            categoryStatus: categoryStatus
        }
    }>
        {props.children}
    </CategoryContext.Provider>
}
