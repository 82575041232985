import React, { createContext } from 'react'
import { ADMIN_BASE_URL } from '../utils/config'
export const adminlogin = createContext()

async function login(data) {
    var rawdata = await fetch(`${ADMIN_BASE_URL}/auth/login`, {
        method: "post",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(data)
        
    })
   
    return await rawdata.json()
}

export default function LoginCantextProvider(props) {
    return <adminlogin.Provider value={
        {
            login: login
        }
    }>
        {props.children}

    </adminlogin.Provider>
}
