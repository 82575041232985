import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ProductContext } from '../../store/ProductContextProvider';

export default function ProductVariantAdd() {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { getProductById, productVariantAdd } = useContext(ProductContext);
    let navigate = useNavigate();
    let { id } = useParams();

    const [product, setProduct] = useState({
        product_name: '',
        color: '',
        sizes: [{ size: '', stock: '' }],
        short_description: '',
        long_description: '',
        price: '',
        discount: '',
        sku: '',
        useMainImage: true,
        image: '',
        secondary_images: [],
        existingImage: '', // For displaying the main image preview
        existingSecondaryImages: [] // For displaying secondary images preview
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getAPIData();
    }, []);

    async function getAPIData() {
        if (!id) {
            // console.error('ID is undefined or null');
            return;
        }

        try {
            const response = await getProductById(id);
            if (response && response.data) {
                const variant = response.data;

                setProduct({
                    ...response.data,
                    sizes: Array.isArray(response.data.sizes) ? response.data.sizes : [{ size: '', stock: '' }],
                });
            }
        } catch (error) {
            // console.error('Error fetching product:', error);
        }
    }


    function getData(e, index = null) {
        const { name, value } = e.target;

        if (index !== null) {
            // Update a specific size in the sizes array
            const updatedSizes = product.sizes.map((size, i) =>
                i === index ? { ...size, [name]: value } : size
            );
            setProduct((oldData) => ({
                ...oldData,
                sizes: updatedSizes
            }));
        } else {
            // Update top-level fields
            setProduct((oldData) => ({
                ...oldData,
                [name]: value
            }));
        }
    }

    function getFile(e) {
        const file = e.target.files[0];
        if (file) {
            setProduct((oldData) => ({
                ...oldData,
                image: file
            }));
        }
    }




    function getSecondaryFile(e) {
        const file = e.target.files[0];
        if (file) {
            setProduct((oldData) => ({
                ...oldData,
                secondary_images: file
            }));
        }
    }

    const handleAddSize = () => {
        setProduct({
            ...product,
            sizes: [...product.sizes, { size: '', stock: '' }]
        });
    };

    const handleRemoveSize = (index) => {
        const sizes = product.sizes.filter((_, i) => i !== index);
        setProduct({ ...product, sizes });
    };

    function validateFields() {
        let tempErrors = {};

        if (!product.color) tempErrors.color = "Color is required";
        if (product.sizes.some(size => !size.size || !size.stock || isNaN(size.stock))) {
            tempErrors.sizes = "All sizes must have valid size and stock";
        }
        // if (!product.short_description) tempErrors.short_description = "Short description is required";
        // if (!product.long_description) tempErrors.long_description = "Long description is required";
        // if (!product.price || isNaN(product.price)) tempErrors.price = "Valid price is required";
        // if (product.discount && (isNaN(product.discount) || product.discount < 0 || product.discount > 100)) {
        //     tempErrors.discount = "Discount must be between 0 and 100";
        // }
        // if (!product.sku) tempErrors.sku = "SKU is required";
        // if (!product.useMainImage && !product.image) tempErrors.image = "Please upload an image or use the main product image";
        // if (product.secondary_images.length > 5) tempErrors.secondary_images = "You can upload a maximum of 5 secondary images";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    async function postData(e) {
        e.preventDefault();

        setIsLoading(true); // Show loader
        setIsButtonDisabled(true); // Disable button
        // console.log("product : ", product)

        // Call the context API to update the product
        const response = await productVariantAdd(product, id);
        // console.log("response>>>>>>>>>>>>>>>>>>>>>>>> : ",response);
        if (response.status === true) {
            localStorage.setItem('toast', 'Product variant has been updated successfully');
            navigate("/product-list");
        } else {
            alert(response.message);
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    }


    return (
        <div className="page-wrapper">
            <Header />
            <Sidebar />

            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <div className="ibox">
                        <div className="ibox-head">
                            <div className="ibox-title">
                                <h3>Product Variants</h3>
                            </div>
                            <Link to="/product-list" className="btn btn-secondary pull-right">
                                Back to Products
                            </Link>
                        </div>
                        <div className="ibox-body">
                            <form method="post" onSubmit={postData}>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <Form.Group controlId="formColor">
                                            <Form.Label>Color</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="color"
                                                value={product.color}
                                                onChange={getData}
                                                isInvalid={!!errors.color}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.color}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Form.Label>Sizes</Form.Label>
                                        {product.sizes && Array.isArray(product.sizes) && product.sizes.map((sizeData, index) => (
                                            <Row key={index} className="mb-2">
                                                <Col md={5}>
                                                    <Form.Group controlId={`formSize-${index}`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="size"
                                                            placeholder="Size (e.g., 12X324)"
                                                            value={sizeData.size}
                                                            onChange={(e) => getData(e, index)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={5}>
                                                    <Form.Group controlId={`formStock-${index}`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="stock"
                                                            placeholder="Stock"
                                                            value={sizeData.stock}
                                                            onChange={(e) => getData(e, index)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Button variant="danger" onClick={() => handleRemoveSize(index)}>
                                                        Remove
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Button variant="secondary" onClick={handleAddSize}>
                                            Add Another Size
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={4}>
                                        <Form.Group controlId="formPrice">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="price"
                                                value={product.price}
                                                onChange={getData}
                                                isInvalid={!!errors.price}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.price}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formDiscount">
                                            <Form.Label>Discount (%)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="discount"
                                                value={product.discount}
                                                onChange={getData}
                                                isInvalid={!!errors.discount}
                                                maxLength={2}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.discount}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formSKU">
                                            <Form.Label>SKU</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="sku"
                                                value={product.sku}
                                                onChange={getData}
                                                isInvalid={!!errors.sku}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.sku}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group controlId="formExistingImage">
                                            <Form.Label>Existing Image</Form.Label>
                                            {product.image && (
                                                <div className="mb-2">
                                                    <img
                                                        src={product.image}
                                                        alt="Existing"
                                                        style={{ maxHeight: '100px', marginBottom: '10px' }}

                                                    />
                                                </div>
                                            )}
                                            <Form.Label>Upload New Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="image"
                                                onChange={getFile}
                                                isInvalid={!!errors.image}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.image}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="formExistingSecondaryImages">
                                            <Form.Label>Existing Secondary Images</Form.Label>
                                            {product.secondary_images.length > 0 && (
                                                <div className="mb-2">


                                                    {product.secondary_images.length > 0 ? (
                                                        product.secondary_images.map((image, i) => (
                                                            <img
                                                                key={i}
                                                                src={image}
                                                                alt={`Secondary Image ${i + 1}`}
                                                                style={{ width: '70px', height: '70px', objectFit: 'cover', marginRight: '10px', marginBottom: '10px' }}
                                                                className="rounded border"
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="text-muted">No secondary images provided</p>
                                                    )}
                                                </div>
                                            )}
                                            <Form.Label>Upload Secondary Images (Max 5)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="secondary_images"
                                                multiple
                                                onChange={getSecondaryFile}
                                                isInvalid={!!errors.secondary_images}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.secondary_images}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Form.Group controlId="formShortDescription">
                                            <Form.Label>Short Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="short_description"
                                                value={product.short_description}
                                                onChange={getData}
                                                isInvalid={!!errors.short_description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.short_description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Form.Group controlId="formLongDescription">
                                            <Form.Label>Long Description</Form.Label>
                                            <ReactQuill
                                                value={product.long_description}
                                                onChange={(value) => setProduct({ ...product, long_description: value })}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['bold', 'italic', 'underline'],
                                                        [{ 'align': [] }],
                                                        ['link', 'image'],
                                                        ['clean'],
                                                    ],
                                                }}
                                                formats={[
                                                    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline',
                                                    'align', 'link', 'image'
                                                ]}
                                                style={{ height: '200px' }}
                                            />
                                            {errors.long_description && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errors.long_description}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button type="submit" className="btn btn-primary" disabled={isButtonDisabled}>
                                {isLoading ? 'Variant Adding...' : 'Add Variant'}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
