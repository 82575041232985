import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './views/App'
import LoginContextProvider from './store/LoginContextProvider';
import BannerContextProvider from './store/BannerContextProvider';
import CategoryContextProvider from './store/CategoryContextProvider';
import CustomerContextProvider from './store/CustomerContextProvider';
import ProductContextProvider from './store/ProductContextProvider';
import SubadminContextProvider from './store/SubadminContextProvider';
import PromocodeContextProvider from './store/PromocodeContextProvider';
import AllContextProvider from './store/AllContextProvider';
import FaqsContextProvider from './store/FaqsContextProvider';
import AttributesContext from './store/AttributeContextProvider';
import ReturnTypesContextProvider from './store/ReturnTypesContextProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <LoginContextProvider>
      <BannerContextProvider>
        <CategoryContextProvider>
          <ProductContextProvider>
            <CustomerContextProvider>
              <SubadminContextProvider>
                <PromocodeContextProvider>
                  <AllContextProvider>
                    <FaqsContextProvider>
                      <AttributesContext>
                        <ReturnTypesContextProvider>
                          <App />
                        </ReturnTypesContextProvider>
                      </AttributesContext>
                    </FaqsContextProvider>
                  </AllContextProvider>
                </PromocodeContextProvider>
              </SubadminContextProvider>
            </CustomerContextProvider>
          </ProductContextProvider>
        </CategoryContextProvider>
      </BannerContextProvider>
    </LoginContextProvider>
  </>

);
