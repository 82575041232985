import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { CustomerContext } from '../../store/CustomerContextProvider';
import { useNavigate } from 'react-router-dom'
import { countryCodes } from '../../utils/helper'


export default function AddCustomer() {

    const [errors, setErrors] = useState({});
    const [imageError, setImageError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    var [Customer, setCustomer] = useState({
        firstname: "",
        lastname: "",
        email: "",
        gender: "",
        country_code: "",
        mobile_no: "",
        dob: "",
        addressStreet: "",
        addressCity: "",
        addressState: "",
        addressPostal_code: "",
        referred_by: "",
        image: null
    });

    var { addCustomer } = useContext(CustomerContext);
    var navigate = useNavigate();


    function getData(e) {
        var name = e.target.name;
        var value = e.target.value;
        setCustomer((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    function getFile(e) {
        var file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                setImageError("File must be an image");
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                setImageError("Image size should be less than 2MB");
                return;
            }
            setImageError("");
            setCustomer((oldData) => {
                return {
                    ...oldData,
                    image: file
                }
            });
        }
    }

    function validateFields() {
        let tempErrors = {};
        if (!Customer.firstname) tempErrors.firstname = "firstname is required";
        if (!Customer.email) tempErrors.email = "email is required";
        // if (!Customer.description) tempErrors.description = "Description is required";
        // if (!Customer.image) tempErrors.image = "Image is required";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    async function postData(e) {
        e.preventDefault();
        if (!validateFields()) return;
        setIsLoading(true); // Show loader
        setIsButtonDisabled(true); // Disable button

        const response = await addCustomer(Customer);
        console.log(response);

        if (response.result.status === 200) {
            localStorage.setItem('toast', 'Customer has created successfully');
            navigate("/customer");
        }
        else
            alert(response.message);
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
    }

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Customer - Add</h3>
                                </div>
                                <Link to="/Customer" className="btn btn-secondary pull-right">Back</Link>
                            </div>
                            <div className="ibox-body">
                                <form method="post" onSubmit={postData}>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="firstname" className="control-label">First Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="firstname" onChange={getData} maxLength="100" />
                                                {errors.firstname && <small className="text-danger">{errors.firstname}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="lastname" className="control-label">Last Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="lastname" onChange={getData} maxLength="100" />
                                                {errors.lastname && <small className="text-danger">{errors.lastname}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="email" className="control-label">Email<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="email" onChange={getData} maxLength="100" />
                                                {errors.email && <small className="text-danger">{errors.email}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">

                                            <div className="form-group">
                                                <label htmlFor="gender" className="control-label">
                                                    Gender<span className="text-danger">*</span>
                                                </label>
                                                <select className="form-control" name="gender" onChange={getData}>
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.gender && <small className="text-danger">{errors.gender}</small>}
                                            </div>


                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="country_code" className="control-label">Country Code<span className="text-danger">*</span></label>
                                                <select className="form-control" name="country_code" onChange={getData}>
                                                    <option value="">Select Country Code</option>
                                                    {countryCodes.map((country, index) => (
                                                        <option key={index} value={country.code}>{country.name} ({country.code})</option>
                                                    ))}
                                                </select>
                                                {errors.country_code && <small className="text-danger">{errors.country_code}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="mobile_no" className="control-label">Mobile Number<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="mobile_no" onChange={getData} maxLength="100" />
                                                {errors.mobile_no && <small className="text-danger">{errors.mobile_no}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="dob" className="control-label">Date of birth<span className="text-danger">*</span></label>
                                                <input type="date" className="form-control" name="dob" onChange={getData} maxLength="100" />
                                                {errors.dob && <small className="text-danger">{errors.dob}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="addressStreet" className="control-label">Address Street<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="addressStreet" onChange={getData} maxLength="100" />
                                                {errors.addressStreet && <small className="text-danger">{errors.addressStreet}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="addressCity" className="control-label">Address City<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="addressCity" onChange={getData} maxLength="100" />
                                                {errors.addressCity && <small className="text-danger">{errors.addressCity}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="addressState" className="control-label">Address State<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="addressState" onChange={getData} maxLength="100" />
                                                {errors.addressState && <small className="text-danger">{errors.addressState}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="addressPostal_code" className="control-label">Address Postal Code<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="addressPostal_code" onChange={getData} maxLength="100" />
                                                {errors.addressPostal_code && <small className="text-danger">{errors.addressPostal_code}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="referred_by" className="control-label">Referred by<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="referred_by" onChange={getData} maxLength="100" />
                                                {errors.referred_by && <small className="text-danger">{errors.referred_by}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="image" className="control-label">Image<span className="text-danger">*</span></label>
                                                <input type="file" className="form-control" name="image" onChange={getFile} />
                                                {imageError && <small className="text-danger">{imageError}</small>}
                                                {errors.image && <small className="text-danger">{errors.image}</small>}
                                            </div>
                                        </div>
                                        <div className="col-md-5">

                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-1">
                                            <Link to="/Customer" className="btn btn-secondary pull-right">Back</Link>
                                        </div>
                                        <div className="col-md-8 text-center">
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-rounded btn-block" type="submit" disabled={isButtonDisabled}>
                                                {isLoading ? 'Customer Adding...' : 'Add Customer'}  
                                                    </button>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
