import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const BannerContext = createContext()


async function addBanner(banner) {

    var item = new FormData();
    item.append('banner_title', banner.name);
    item.append('banner_url', banner.names);
    item.append('image', banner.image);
    item.append('description', banner.description);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/banners`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function getBanner(page,itemsPerPage,searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },
       
    }
    var rawdata = await fetch(`${ADMIN_BASE_URL}/banners?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)
    // var rawdata = await fetch(`${ADMIN_BASE_URL}/banners?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    var result = await rawdata.json()

    if (result)
        return { result: "Done", data: result.data }
    else
        return { result: "Fail", message: "Internal Server Error" }
}




async function getBannerById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/banners/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function bannerUpdate(banner, _id) {

    var item = new FormData();

    item.append('banner_title', banner.banner_title);
    item.append('banner_url', banner.banner_url);
    item.append('image', banner.image);
    item.append('description', banner.description);

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/banners/${_id}`, item, config);
            console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}



async function deleteBanner(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/banners/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Banner is Deleted" }
}



async function bannerStatus(_id,status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        status : status
    }
    var result = await axios.put(`${ADMIN_BASE_URL}/banners/status/${_id}`,obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Banner is Deleted" }
}



export default function BannerContextProvider(props) {
    return <BannerContext.Provider value={
        {
            addBanner: addBanner,
            getBanner: getBanner,
            getBannerById: getBannerById,
            bannerUpdate: bannerUpdate,
            deleteBanner: deleteBanner,
            bannerStatus: bannerStatus
        }
    }>
        {props.children}
    </BannerContext.Provider>
}
