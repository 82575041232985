export const creditDays = ["0" , "7", "15", "30", "45", "60", "90"];

 const countryCodes = [
    { code: "+1", name: "United States" },
    { code: "+44", name: "United Kingdom" },
    { code: "+91", name: "India" },
    { code: "+81", name: "Japan" },
    { code: "+61", name: "Australia" },
    { code: "+49", name: "Germany" },
    { code: "+33", name: "France" },
    { code: "+39", name: "Italy" },
    { code: "+86", name: "China" },
    { code: "+7", name: "Russia" },
    { code: "+34", name: "Spain" },
    { code: "+55", name: "Brazil" },
    { code: "+27", name: "South Africa" },
    { code: "+82", name: "South Korea" },
    { code: "+34", name: "Mexico" },
    { code: "+60", name: "Malaysia" },
    { code: "+64", name: "New Zealand" },
    { code: "+20", name: "Egypt" },
    { code: "+52", name: "Mexico" },
    { code: "+41", name: "Switzerland" },
    { code: "+31", name: "Netherlands" },
    { code: "+46", name: "Sweden" },
    { code: "+48", name: "Poland" },
    { code: "+32", name: "Belgium" },
    { code: "+43", name: "Austria" },
    { code: "+30", name: "Greece" },
    { code: "+47", name: "Norway" },
    { code: "+45", name: "Denmark" },
    { code: "+351", name: "Portugal" },
    { code: "+353", name: "Ireland" },
    { code: "+972", name: "Israel" },
    { code: "+62", name: "Indonesia" },
    { code: "+66", name: "Thailand" },
    { code: "+63", name: "Philippines" },
    { code: "+90", name: "Turkey" },
    { code: "+386", name: "Slovenia" },
    { code: "+386", name: "Slovenia" },
    { code: "+372", name: "Estonia" },
    { code: "+371", name: "Latvia" },
    { code: "+370", name: "Lithuania" },
    { code: "+356", name: "Malta" },
    { code: "+357", name: "Cyprus" },
    { code: "+357", name: "Cyprus" },
    { code: "+30", name: "Greece" },
    { code: "+357", name: "Cyprus" },
    { code: "+40", name: "Romania" },
    { code: "+386", name: "Slovenia" },
    { code: "+420", name: "Czech Republic" },
    { code: "+421", name: "Slovakia" },
    { code: "+36", name: "Hungary" },
    { code: "+380", name: "Ukraine" },
    { code: "+7", name: "Russia" },
    { code: "+995", name: "Georgia" },
    { code: "+374", name: "Armenia" },
    { code: "+994", name: "Azerbaijan" },
    { code: "+993", name: "Turkmenistan" },
    { code: "+992", name: "Tajikistan" },
    { code: "+996", name: "Kyrgyzstan" },
    { code: "+998", name: "Uzbekistan" },
    { code: "+84", name: "Vietnam" },
    { code: "+95", name: "Myanmar" },
    { code: "+880", name: "Bangladesh" },
    { code: "+977", name: "Nepal" },
    { code: "+94", name: "Sri Lanka" },
    { code: "+968", name: "Oman" },
    { code: "+974", name: "Qatar" },
    { code: "+971", name: "United Arab Emirates" },
    { code: "+973", name: "Bahrain" },
    { code: "+966", name: "Saudi Arabia" },
    { code: "+964", name: "Iraq" },
    { code: "+965", name: "Kuwait" },
    { code: "+962", name: "Jordan" },
    { code: "+961", name: "Lebanon" },
    { code: "+216", name: "Tunisia" },
    { code: "+213", name: "Algeria" },
    { code: "+212", name: "Morocco" },
    { code: "+254", name: "Kenya" },
    { code: "+256", name: "Uganda" },
    { code: "+250", name: "Rwanda" },
    { code: "+255", name: "Tanzania" },
    { code: "+258", name: "Mozambique" },
    { code: "+267", name: "Botswana" },
    { code: "+260", name: "Zambia" },
    { code: "+263", name: "Zimbabwe" },
    { code: "+27", name: "South Africa" },
    { code: "+234", name: "Nigeria" },
    { code: "+233", name: "Ghana" },
    { code: "+225", name: "Ivory Coast" },
    { code: "+221", name: "Senegal" },
    { code: "+251", name: "Ethiopia" },
    { code: "+237", name: "Cameroon" },
    { code: "+241", name: "Gabon" },
    { code: "+256", name: "Uganda" },
    { code: "+234", name: "Nigeria" },
    { code: "+212", name: "Morocco" },
    { code: "+213", name: "Algeria" },
    { code: "+216", name: "Tunisia" },
    { code: "+218", name: "Libya" },
    // Add more country codes as needed
];

export { countryCodes };