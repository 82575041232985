import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ReactQuill from 'react-quill';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { CategoryContext } from '../../store/CategoryContextProvider';
import { ProductContext } from '../../store/ProductContextProvider';

import 'react-quill/dist/quill.snow.css';
import { useNavigate,useParams } from 'react-router-dom'

export default function ProductEdit() {
    let navigate = useNavigate();
    const { getCategory } = useContext(CategoryContext);
    const { addProduct, getProductById,updateProduct } = useContext(ProductContext);
    const [formData, setFormData] = useState({
        product_name: '',
        product_url : '',
        quantity_unit: '',
        category_id: '',
        sub_category_id: '',
        short_description: '',
        long_description: '',
        image: '',
        secondary_images: [],
        price: '',
        discount: '',
        video_url: '',
       
    });

    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [Allcategories, setAllCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    var {_id} = useParams()

    const generateProductUrl = (name) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace spaces and special characters with hyphens
            .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        let newFormData = { ...formData };

        if (type === 'file') {
            if (name === 'image') {
                newFormData[name] = files[0];
            } else if (name === 'secondary_images') {
                newFormData[name] = Array.from(files);
            }
        } else {
            newFormData[name] = value;

            // Automatically update Product Url based on Product Name
            
        }

        setFormData(newFormData);
    };

    const handleLongDescriptionChange = (content) => {
        setFormData((prevState) => ({
            ...prevState,
            long_description: content,
        }));
    };

    const handleNumericChange = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value) || value === '') {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {


            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button


            const formDataToSubmit = new FormData();

            // Append form data fields
            formDataToSubmit.append('product_name', formData.product_name);
            formDataToSubmit.append('product_url', formData.product_url);
            
            formDataToSubmit.append('quantity_unit', formData.quantity_unit);
            formDataToSubmit.append('category_id', formData.category_id);
            formDataToSubmit.append('sub_category_id', formData.sub_category_id);
            formDataToSubmit.append('short_description', formData.short_description);
            formDataToSubmit.append('long_description', formData.long_description);
            formDataToSubmit.append('price', formData.price);
            formDataToSubmit.append('discount', formData.discount);
            formDataToSubmit.append('video_url', formData.video_url);
            

            // Append main image file
            if (formData.image) {
                formDataToSubmit.append('image', formData.image);
            }

            // Append secondary images
            if (formData.secondary_images.length > 0) {
                formData.secondary_images.forEach((file) => {
                    formDataToSubmit.append('secondary_images', file);
                });
            }

            // Log the formData content (optional for debugging)
            for (let pair of formDataToSubmit.entries()) {
                // console.log(pair[0] + ':', pair[1]);
            }

            // Send form data to addProduct API
            const response = await updateProduct(formDataToSubmit,_id);
            // console.log(response.result.data.data._id);
            console.log(response);
    

            if (response.result.status === 200) {
                localStorage.setItem('toast', 'Product has been created successfully');
                navigate(`/product-list`);
            } else {
                alert('Something went wrong, product not added');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        }
    };


    const validateFields = () => {
        let tempErrors = {};
        if (!formData.product_name) tempErrors.product_name = "Product name is required";
        if (!formData.product_url) tempErrors.product_url = "Product Url is required";
        
        if (!formData.short_description) tempErrors.short_description = "Short description is required";
        if (!formData.long_description) tempErrors.long_description = "Long description is required";
        if (!formData.price) tempErrors.price = "Price is required";
        if (formData.discount && (formData.discount < 0 || formData.discount > 100)) tempErrors.discount = "Discount must be between 0 and 100";
        if (!formData.image) tempErrors.image = "Image is required";
        if (formData.secondary_images.length > 5) tempErrors.secondary_images = "You can upload a maximum of 5 secondary images";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    // Fetch categories and subcategories
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCategories();
    }, []);
    const fetchCategories = async () => {
        try {
            let response = await getCategory(1, 50, '');
            // console.log('response', response);
            if (response.result == 'Done') {
                let categories = response.data.docs
                setAllCategories(categories)
                let filteredCategories = response.data.docs.filter(category => category.parent_id == '66ad2ac0a4c6b61fd84863b8'); // need to change on live if parent_id change
                setCategories(filteredCategories); // Assuming the API returns a 'categories' array
            } else {
                // console.error('Failed to fetch categories');
            }
        } catch (error) {
            // console.error('Error:', error);
        }
    };
    // Handle category change and filter subcategories
    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setFormData({ ...formData, category_id: selectedCategoryId });

        // Assuming subcategories are part of the category data
        const selectedCategory = Allcategories.find(cat => {
            return cat.parent_id == selectedCategoryId
        });

        const selectedCategoryArray = selectedCategory ? [selectedCategory] : [];
        if (selectedCategory) {
            setSubCategories(selectedCategoryArray || []);
        } else {
            setSubCategories([]);
        }
    };

    const handleSubCategoryChange = (e) => {
        setFormData({ ...formData, sub_category_id: e.target.value });
    };


    async function getAPIData() {
        if (!_id) {
          console.error('ID is undefined or null');
          return;
        }
    
       
        // console.log(item);
    
        try {
          var response = await getProductById(_id);
          
          console.log( 'response>>>>>>>>>>>>>>>>>>>>>>>:',  response);
          
          setFormData(response.data);
        } catch (error) {
          console.error('Error fetching banner:', error);
        }
      }




    useEffect(() => {
        getAPIData()
      }, [])
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />

                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Product - Edit</h3>
                                </div>
                                <Link to="/product-list" className="btn btn-secondary pull-right">
                                    Back To Product
                                </Link>
                            </div>
                            <div className="ibox-body">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formName">
                                                <Form.Label>Product Name <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="product_name"
                                                    value={formData.product_name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.product_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.product_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formProductUrl">
                                                <Form.Label>Product Url</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="product_url"
                                                    value={formData.product_url}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.product_url}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.product_url}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formCategoryId">
                                                <Form.Label>Category <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="category_id"
                                                    value={formData.category_id}
                                                    onChange={handleCategoryChange}
                                                    isInvalid={!!errors.category_id}
                                                >
                                                    <option value="">Select Category</option>
                                                    {categories.map(category => (
                                                        <option key={category._id} value={category._id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.category_id}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formSubCategoryId">
                                                <Form.Label>Sub-Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="sub_category_id"
                                                    value={formData.sub_category_id}
                                                    onChange={handleSubCategoryChange}
                                                    disabled={!subCategories.length}
                                                >
                                                    <option value="">Select Sub-Category</option>
                                                    {subCategories.map(subCategory => (
                                                        <option key={subCategory._id} value={subCategory._id}>
                                                            {subCategory.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>

                                        </Col>
                                        <Col md={6}>

                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formPrice">
                                                <Form.Label>Price <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleNumericChange}
                                                    isInvalid={!!errors.price}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.price}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formDiscount">
                                                <Form.Label>Discount (%)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="discount"
                                                    value={formData.discount}
                                                    onChange={handleNumericChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formQuantityUnit">
                                                <Form.Label>Quantity Unit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="quantity_unit"
                                                    value={formData.quantity_unit}
                                                    onChange={handleNumericChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formVideoUrl">
                                                <Form.Label>Video URL</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="video_url"
                                                    value={formData.video_url}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="formImage">
                                                <Form.Label>Product Image <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="image"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.image}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formSecondaryImages">
                                                <Form.Label>Secondary Images (Max 5)</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="secondary_images"
                                                    multiple
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.secondary_images}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.secondary_images}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Form.Group controlId="formShortDescription">
                                                <Form.Label>Short Description <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="short_description"
                                                    value={formData.short_description}
                                                    onChange={handleChange}
                                                    rows={3}
                                                    isInvalid={!!errors.short_description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.short_description}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Form.Group controlId="formLongDescription">
                                                <Form.Label>Long Description <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <ReactQuill
                                                    value={formData.long_description}
                                                    onChange={handleLongDescriptionChange}
                                                    modules={{
                                                        toolbar: [
                                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            ['bold', 'italic', 'underline'],
                                                            [{ 'align': [] }],
                                                            ['link', 'image'],
                                                            ['clean'],
                                                        ],
                                                    }}
                                                    formats={[
                                                        'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline',
                                                        'align', 'link', 'image'
                                                    ]}
                                                    style={{ height: '200px' }} // Optional: Control the editor height
                                                />
                                                {errors.long_description && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.long_description}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                    <Row className="mb-3 float-right">
                                        <Col md={5}  ></Col>
                                        <Col md={6}  >
                                            <Link to="/category" className="btn btn-secondary ml-3">
                                                Back
                                            </Link>
                                            &nbsp;
                                            <Button type="submit" className="btn btn-primary" disabled={isButtonDisabled}>
                                            {isLoading ? 'Product Updating...' : 'Update Product'}  
                                            </Button>

                                        </Col>
                                        <Col md={1}  ></Col>
                                    </Row>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
