import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ContactUs from './ContactUs';
import Newsletter from './Newsletter';
import SearchKeyword from './SearchKeyword';
import Testimonial from './Testimonial';


export default function BannerRoutes() {
  return (
    <Routes>
      <Route path='/contactus' element={<ContactUs/>} />
      <Route path='/newsletter' element={<Newsletter/>} />
      <Route path='/searchKeyword' element={<SearchKeyword/>}/>
      <Route path='/testimonial' element={<Testimonial/>} />
    </Routes>
  );
}
