import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const ReturnTypesContext = createContext()
//get all products list
async function getReturnTypes(page, itemsPerPage, searchQuery) {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    var response = await axios.get(`${ADMIN_BASE_URL}/return_types?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config)
    console.log("response : ", response)
    if (response.status == 200) {
        return { status: true, data: response.data.data };
    } else {
        return { status: false, message: "Internal Server Error" };
    }
}





async function addReturnTypes(item) {
    // console.log("Form item+++++++++++++", item)
    // return

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            }

        };

        try {
            let result = await axios.post(`${ADMIN_BASE_URL}/return_types`, item, config);
            // console.log('Success:', result);
            if (result)
                return { result }
            else
                return { result: "Fail", message: "Internal Server Error" }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}





async function getReturnTypesById(item) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await fetch(`${ADMIN_BASE_URL}/return_types/${item._id}`, config3);

        let result = await response.json();

        if (result) {
            let { id, ...x } = result;
            return { result: "Done", data: { _id: id, ...x } };
        } else {
            return { result: "Fail", message: "Internal Server Error" };
        }
    } catch (error) {
        return { result: "Fail", message: error.message };
    }
}



async function ReturnTypesUpdate(item, _id) {

    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
    } else {
        let config = {
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            }
        };

        try {
            let result = await axios.put(`${ADMIN_BASE_URL}/return_types/${_id}`, item, config);
            // console.log('Success:', result);
            if (result) {
                return { result };
            } else {
                return { result: "Fail", message: "Internal Server Error" };
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    }
}




async function deleteReturnTypes(item) {
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    var rawdata = await fetch(`${ADMIN_BASE_URL}/return_types/${item._id}`, config3)
    var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "ReturnTypes is Deleted" }
}



async function ReturnTypesStatus(_id, status) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        status: status
    }
    var result = await axios.put(`${ADMIN_BASE_URL}/return_types/status/${_id}`, obj, config3)
    // var result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "ReturnTypes is Deleted" }
}







export default function ReturnTypesContextProvider(props) {
  return <ReturnTypesContext.Provider value={
    {
        getReturnTypes: getReturnTypes,
        addReturnTypes: addReturnTypes,
        getReturnTypesById: getReturnTypesById,
        ReturnTypesUpdate: ReturnTypesUpdate,
        deleteReturnTypes: deleteReturnTypes,
        ReturnTypesStatus: ReturnTypesStatus,
       
    }
}>
    {props.children}
</ReturnTypesContext.Provider>
}
