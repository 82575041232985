import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { CategoryContext } from '../../store/CategoryContextProvider';

export default function EditCategory() {
  const { getCategoryById, categoryUpdate, getCategory } = useContext(CategoryContext);
  const navigate = useNavigate();
  const { _id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [category, setCategory] = useState({
    name: '',
    parent_id: '',
    level: '',
    categoryName: '',
    image: null,
    description: '',
    meta_title: '',
    meta_keyword: '',
    meta_description: '',
  });
  const [errors, setErrors] = useState({});
  const [categoryTree, setCategoryTree] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [categoryStatus, setCategoryStatus] = useState('');
  const [imageError, setImageError] = useState('');

  useEffect(() => {
    fetchCategoryData();
    fetchCategories();
  }, [_id]);

  const fetchCategoryData = async () => {
    try {
      const response = await getCategoryById({ _id });
      console.log("Item >>>>>>>>>>>>>>>>>>>>>>>>>>>>", response.data.data.docs[0]);

      setCategory(response.data.data.docs[0]);
    } catch (error) {
      console.error('Error fetching category:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategory(1, 50, '');
      const categories = response.data.docs;
      setCategoryTree(categories);

      const rootCategories = categories.filter(cat => cat.parent_id === null);
      const expandedNodesInit = {};
      rootCategories.forEach(rootCat => {
        expandedNodesInit[rootCat._id] = true;
      });

      categories.forEach(cat => {
        if (rootCategories.some(rootCat => rootCat._id === cat.parent_id)) {
          expandedNodesInit[cat._id] = true;
        }
      });

      setExpandedNodes(expandedNodesInit);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevCategory) => ({
      ...prevCategory,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setImageError('File must be an image');
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        setImageError('Image size should be less than 2MB');
        return;
      }
      setImageError('');
      setCategory((prevCategory) => ({
        ...prevCategory,
        image: file,
      }));
    }
  };

  const validateFields = () => {
    const tempErrors = {};
    if (!category.name) tempErrors.categoryName = 'Category Name is required'; // Changed from category.categoryName to category.name
    if (!category.parent_id) tempErrors.parent_id = 'Parent Category must be selected'; // Added a check for parent_id if needed
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button

    try {
      const formData = new FormData();
      for (const key in category) {
        formData.append(key, category[key]);
      }

      const response = await categoryUpdate(formData, _id);

      if (response.result.status === 200) {
        localStorage.setItem('toast', 'Category has been updated successfully');
        navigate('/category');
      } else {
        alert(response.message);
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
      }
    } catch (error) {
      console.error('Error updating category:', error);
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    }
  };

  const getCatID = (name, _id, level) => {
    setCategory({
      ...category,
      name: name,
      parent_id: _id,
      level: level + 1,
    });
  };

  const toggleExpand = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderCategoryTree = (categories, parentId = null) => {
    return (
      <ul style={{ paddingLeft: parentId ? '20px' : '0' }}>
        {categories
          .filter((item) => item.parent_id === parentId)
          .map((item) => (
            <li key={item._id} style={{ marginBottom: '10px' }}>
              {categories.some(cat => cat.parent_id === item._id) && (
                <span
                  onClick={() => toggleExpand(item._id)}
                  style={{ cursor: 'pointer', marginRight: '5px' }}
                >
                  {expandedNodes[item._id] ? '▼' : '▶'}
                </span>
              )}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  getCatID(item.name, item._id, item.level);
                }}
                style={{
                  color: '#007bff',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  display: 'inline-block',
                  padding: '5px',
                  borderRadius: '4px',
                  background: parentId ? '#f9f9f9' : '#e9ecef',
                  marginBottom: '5px',
                }}
              >
                {item.name}
              </a>
              {expandedNodes[item._id] && renderCategoryTree(categories, item._id)}
            </li>
          ))}
      </ul>
    );
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="page-content fade-in-up">
          <div className="ibox">
            <div className="ibox-head">
              <div className="ibox-title">
                <h3>Edit Category</h3>
              </div>
              <Link to="/category" className="btn btn-secondary pull-right">
                Back
              </Link>
            </div>
            <div className="ibox-body">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h5>Category Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Parent Category:
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={category.parentCategoryName}
                              onChange={handleInputChange}
                            />

                            {errors.name && (
                              <small className="text-danger">{errors.name}</small>
                            )}
                            <input type="hidden" name="parent_id" value={category.parent_id} />
                            <input type="hidden" name="level" value={category.level} />
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Category Name: <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="categoryName"
                              value={category.name}
                              onChange={handleInputChange}
                              onKeyUp={validateFields}
                            />
                            {errors.categoryName && (
                              <small className="text-danger">{errors.categoryName}</small>
                            )}
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Upload Image:
                          </label>
                          <div className="col-md-7">
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              onChange={handleFileChange}
                            />
                            {imageError && <small className="text-danger">{imageError}</small>}
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Description:
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              name="description"
                              value={category.description}
                              onChange={handleInputChange}
                              rows="4"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-4">
                      <div className="card-header">
                        <h5>SEO Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Meta Title: <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <input
                              className="form-control"
                              type="text"
                              name="meta_title"
                              value={category.meta_title}
                              onChange={handleInputChange}
                            />
                            {errors.meta_title && (
                              <small className="text-danger">{errors.meta_title}</small>
                            )}
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Meta Keyword: <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <input
                              className="form-control"
                              type="text"
                              name="meta_keyword"
                              value={category.meta_keyword}
                              onChange={handleInputChange}
                            />
                            {errors.meta_keyword && (
                              <small className="text-danger">{errors.meta_keyword}</small>
                            )}
                          </div>
                        </div>
                        <div className="form-group row" style={{ marginBottom: '20px' }}>
                          <label className="col-md-4 col-form-label text-right">
                            Meta Description: <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              name="meta_description"
                              value={category.meta_description}
                              onChange={handleInputChange}
                              rows="3"
                            ></textarea>
                            {errors.meta_description && (
                              <small className="text-danger">{errors.meta_description}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <h5 style={{ color: '#007bff', textAlign: 'center', marginBottom: '15px' }}>
                      Tree of Category
                    </h5>
                    <div
                      style={{
                        maxHeight: '700px',
                        overflowY: 'auto',
                        border: '1px solid #ddd',
                        padding: '10px',
                        borderRadius: '4px',
                        backgroundColor: '#f8f9fa',
                      }}
                    >
                      {renderCategoryTree(categoryTree)}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-8 offset-md-2 text-center">
                    <button className="btn btn-primary btn-rounded" type="submit" disabled={isButtonDisabled}>
                      {isLoading ? 'Category Updating...' : 'Update Category'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
