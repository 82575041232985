import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { adminlogin } from '../../store/LoginContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Login.css';
import '../../assets/css/main.min.css';

const Login = () => {
  const navigate = useNavigate();
  var { login } = useContext(adminlogin);

  const { register, handleSubmit, formState: { errors } } = useForm();

  // State for loader and button disable
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onSubmit = async data => {
    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button

    try {
      const response = await login(data);

      if (response.status === 200) {
        localStorage.setItem("login", true);
        localStorage.setItem("username", response.data.email);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("userid", response.data._id);
        localStorage.setItem("token", response.data.auth_token);
        localStorage.setItem("isToast", true);

        // Redirect with loader still active
        navigate("/dashboard");
      } else {
        // Show error toast if login fails
        toast.error('Invalid Username or Password!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
      }
    } catch (error) {
      console.log("no data", error.message);
      toast.error('An error occurred. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    }
  };

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <div className="ibox login-content">
        <div className="text-center">
          <span className="auth-head-icon"><i className="la la-user"></i></span>
        </div>
        <form className="ibox-body" id="login-form" onSubmit={handleSubmit(onSubmit)}>
          <h4 className="font-strong text-center mb-5">LOG IN</h4>
          <div className="form-group mb-4">
            <input
              className="form-control form-control-line"
              type="text"
              name="username"
              placeholder="Email"
              {...register("username", { required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } })}
            />
            {errors.username && <span className="error-message">{errors.username.message}</span>}
          </div>
          <div className="form-group mb-4">
            <input
              className="form-control form-control-line"
              type="password"
              name="password"
              placeholder="Password"
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && <span className="error-message">{errors.password.message}</span>}
          </div>
          <div className="flexbox mb-5">
            <span>
              <label className="ui-switch switch-icon mr-2 mb-0">
                <input type="checkbox" defaultChecked />
                <span></span>
              </label>Remember
            </span>
            <a className="text-primary" href="forgot_password.html">Forgot password?</a>
          </div>
          <div className="text-center mb-4">
            <button 
              className="btn btn-primary btn-rounded btn-block" 
              type="submit" 
              disabled={isButtonDisabled}
            >
              {isLoading ? 'Logging in...' : 'LOGIN'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
