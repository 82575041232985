import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import AdminPic from '../assets/img/users/u3.jpg';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import '../assets/css/header.css';
import '../../node_modules/themify-icons/themify-icons/fonts/themify.svg'
// import 'themify-icons/css/themify-icons.css'; // Ensure this path is correct

const Header = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleProfileClick = () => {
    navigate('/profile'); // Navigate to profile page
  };

  const handleLogoutClick = () => {
    // Add your logout logic here (e.g., clearing auth tokens)
    navigate('/logout'); // Navigate to logout page
  };

  return (
    <header className="header sticky-header"> {/* Added sticky-header class */}
      <div className="page-brand">
        <a href="/dashboard">
          <span className="brand">Kaleen - Admin </span>
          <span className="brand-mini">WEB</span>
        </a>
      </div>
      <div className="flexbox flex-1">
        <ul className="nav navbar-toolbar">
          <li>
            <a className="nav-link sidebar-toggler js-sidebar-toggler" href="javascript:;">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
          </li>
          <li>
            <a className="nav-link search-toggler js-search-toggler">
              <i className="ti-search"></i>
              <span>Search here...</span>
            </a>
          </li>
        </ul>

        <ul className="nav navbar-toolbar">
          <li className="timeout-toggler">
            <a className="nav-link toolbar-icon" data-toggle="modal" data-target="#session-dialog" href="javascript:;">
              <i className="ti-alarm-clock timeout-toggler-icon rel"><span className="notify-signal"></span></i>
            </a>
          </li>

          <li className="dropdown dropdown-user">
            <a className="nav-link dropdown-toggle link" data-toggle="dropdown">
              <span>Super User</span>
              <img src={AdminPic} alt="image" />
            </a>
            <div className="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu">
              <div className="dropdown-arrow"></div>
              <div className="admin-menu-features">
                <a className="admin-features-item" onClick={handleProfileClick}>
                  <i className="ti-user"></i>
                  <span>PROFILE</span>
                </a>
                <a className="admin-features-item" onClick={handleLogoutClick}>
                  <i className="ti-user"></i>
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </li>
          <li>
            <a className="nav-link quick-sidebar-toggler">
              <span className="ti-align-right"></span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
