import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth = ({ element: Component, ...rest }) => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("login");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Component {...rest} /> : null;
};

export default Auth;
