import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { CustomerContext } from '../../store/CustomerContextProvider';
import { useParams, useNavigate } from 'react-router-dom';
import { countryCodes } from '../../utils/helper'

export default function EditCustomer() {

  // var [name, setname] = useState("")
  
  const [errors, setErrors] = useState({});
  const [imageError, setImageError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  var navigate = useNavigate()
  var { _id } = useParams()

  const [customer, setCustomer] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    country_code: "",
    mobile_no: "",
    dob: "",
    address: {
      street: "",
      city: "",
      state: "",
      postal_code: ""
    },
    referred_by: "",
    image: null
  });
  



  var { getCustomerById, customerUpdate } = useContext(CustomerContext)

  function getData(e) {
    const { name, value } = e.target;
  
    if (name === "street" || name === "city" || name === "state" || name === "postal_code") {
      setCustomer((oldData) => ({
        ...oldData,
        address: {
          ...oldData.address,
          [name]: value
        }
      }));
    } else {
      setCustomer((oldData) => ({
        ...oldData,
        [name]: value
      }));
    }
  }
  

  function getFile(e) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setImageError("File must be an image");
        return;
      }
      if (file.size > 2 * 1024 * 1024) { // Set limit to 2MB
        setImageError("Image size should be less than 2MB");
        return;
      }
      setImageError("");
      setCustomer((oldData) => ({
        ...oldData,
        image: file
      }));
    }
  }

  function validateFields() {
    let tempErrors = {};
    // if (!customer.customer_title) tempErrors.name = "Name is required";
    // if (!customer.customer_url) tempErrors.url = "URL is required";
    // if (!customer.description) tempErrors.description = "Description is required";
    // if (!customer.image) tempErrors.image = "Image is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }

  async function postData(e) {
    e.preventDefault();

    if (!validateFields()) return;

    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button
    const response = await customerUpdate(customer, _id);
    console.log(response);


    if (response.result.status === 200) {
      localStorage.setItem('toast', 'Customer has Update successfully');
      navigate("/customer");
    }
    else
      alert(response.message);
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    // Implement the logic to update the customer
    // const response = await updatecustomer(customer); // Assuming you have an updatecustomer function
    // if (response.result.status === 200) navigate("/customer");
    // else alert(response.message);

    // Remove this line once you implement the update logic
  }


  async function getAPIData() {
    if (!_id) {
      console.error('ID is undefined or null');
      return;
    }

    var item = {
      _id: _id
    };
    // console.log(item);

    try {
      var response = await getCustomerById(item);
      // console.log(response.data.data);
      setCustomer(response.data.data);
    } catch (error) {
      console.error('Error fetching customer:', error);
    }
  }



  useEffect(() => {
    getAPIData()
  }, [])
  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="page-content fade-in-up">
            <div className="ibox">
              <div className="ibox-head">
                <div className="ibox-title">
                  <h3>Customer - Update</h3>
                </div>
                <Link to="/Customer" className="btn btn-secondary pull-right">Back</Link>
              </div>
              <div className="ibox-body">
                <form method="post" onSubmit={postData}>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="firstname" className="control-label">First Name<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="firstname" onChange={getData} value={customer.firstname} maxLength="100" />
                        {errors.firstname && <small className="text-danger">{errors.firstname}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="lastname" className="control-label">Last Name<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="lastname" onChange={getData} value={customer.lastname} maxLength="100" />
                        {errors.lastname && <small className="text-danger">{errors.lastname}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="email" className="control-label">Email<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="email" onChange={getData} value={customer.email} maxLength="100" />
                        {errors.email && <small className="text-danger">{errors.email}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">

                      <div className="form-group">
                        <label htmlFor="gender" className="control-label">
                          Gender<span className="text-danger">*</span>
                        </label>
                        <select className="form-control" name="gender" value={customer.gender} onChange={getData}>
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.gender && <small className="text-danger">{errors.gender}</small>}
                      </div>


                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">

                      <div className="form-group">
                        <label htmlFor="country_code" className="control-label">Country Code<span className="text-danger">*</span></label>
                        <select className="form-control" name="country_code" value={customer.country_code} onChange={getData}>
                          <option value="">Select Country Code</option>
                          {countryCodes.map((country, index) => (
                            <option key={index} value={country.code}>{country.name} ({country.code})</option>
                          ))}
                        </select>
                        {errors.country_code && <small className="text-danger">{errors.country_code}</small>}
                      </div>


                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="mobile_no" className="control-label">Mobile Number<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="mobile_no" onChange={getData} value={customer.mobile_no} maxLength="100" />
                        {errors.mobile_no && <small className="text-danger">{errors.mobile_no}</small>}
                      </div>



                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="dob" className="control-label">Date of birth<span className="text-danger">*</span></label>
                        <input type="date" className="form-control" name="dob" onChange={getData} value={customer.dob} maxLength="100" />
                        {errors.dob && <small className="text-danger">{errors.dob}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="street" className="control-label">Address Street<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="street" onChange={getData} value={customer && customer.address ? customer.address.street : ''} maxLength="100" />
                        {errors.street && <small className="text-danger">{errors.street}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="city" className="control-label">Address City<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="city" onChange={getData} value={customer && customer.address ? customer.address.city : ''} maxLength="100" />
                        {errors.city && <small className="text-danger">{errors.city}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="state" className="control-label">Address state<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="state" onChange={getData} value={customer && customer.address ? customer.address.state : ''} maxLength="100" />
                        {errors.state && <small className="text-danger">{errors.state}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="postal_code" className="control-label">Address Postal Code<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="postal_code" onChange={getData} value={customer && customer.address ? customer.address.postal_code : ''} maxLength="100" />
                        {errors.postal_code && <small className="text-danger">{errors.postal_code}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="referred_by" className="control-label">Referred by<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="referred_by" onChange={getData} value={customer.referred_by} maxLength="100" />
                        {errors.referred_by && <small className="text-danger">{errors.referred_by}</small>}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="image" className="control-label">Image<span className="text-danger">*</span></label>
                        <input type="file" className="form-control" name="image" onChange={getFile} />
                        {imageError && <small className="text-danger">{imageError}</small>}
                        {errors.image && <small className="text-danger">{errors.image}</small>}
                      </div>
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-1">
                      <Link to="/Customer" st className="btn btn-secondary pull-right">Back</Link>
                    </div>
                    <div className="col-md-8 text-center">
                      <div className="form-group">
                        <button className="btn btn-primary btn-rounded btn-block" type="submit" disabled={isButtonDisabled}>
                        {isLoading ? 'Customer Updating...' : 'Update Customer'} 
                          </button>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
